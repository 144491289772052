import { makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

export interface IIconCircleProps extends React.HTMLAttributes<HTMLDivElement> {
	icon: React.ReactNode;
	colors?: string[];
}

interface IStyleProps {
	colors: string[];
}

const useStyles = makeStyles<Theme, IStyleProps>(theme => ({
	icon: {
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundImage: props => `linear-gradient(136deg, ${props.colors[0]} 0%, ${props.colors[1]} 100%)`,
	},
}));

export const IconCircle = (props: IIconCircleProps) => {
	const colors = props.colors || ['rgb(80, 117, 87)', 'rgb(50, 74, 55)'];

	const classes = useStyles({ colors });

	return <div className={classNames([classes.icon, props.className])}>{props.icon}</div>;
};
