import { greens } from 'Common/Colors';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { largeNumber } from 'Common/Helpers/TextHelper';
import { Ownership, ownershipOrder } from 'Models/DetailsModels';
import React, { MutableRefObject } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';
import { TooltipComponents } from './Common';
import settings from 'settings';

interface IOwnershipChangeColumnChart {
	data: Ownership[];
	yValue: 'acres' | 'farms';
	innerRef?: MutableRefObject<any> | null;
}

type Change = {
	acres: number;
	farms: number;
	sizeClassGroupName: string;
};

export const OwnershipChangeColumnChart = React.memo((props: IOwnershipChangeColumnChart) => {
	const nameof = useNameof<Change>();
	const startingYear = settings.displayYears[0];
	const [endingYear] = settings.displayYears.slice(-1);

	const getChange = () => {
		const startingData = props.data
			.filter(d => d.year === startingYear)
			.sort(
				(a, b) => ownershipOrder.indexOf(a.sizeClassGroupName) - ownershipOrder.indexOf(b.sizeClassGroupName)
			);
		const endingData = props.data
			.filter(d => d.year === endingYear)
			.sort(
				(a, b) => ownershipOrder.indexOf(a.sizeClassGroupName) - ownershipOrder.indexOf(b.sizeClassGroupName)
			);

		return startingData.map(
			(d, i) =>
				({
					acres: (endingData[i] ? endingData[i].acres : 0) - d.acres,
					farms: (endingData[i] ? endingData[i].farms : 0) - d.farms,
					sizeClassGroupName: d.sizeClassGroupName,
				} as Change)
		);
	};

	return (
		<>
			<ResponsiveContainer width="99%" minHeight="250px">
				<BarChart
					ref={props.innerRef}
					width={500}
					height={300}
					data={getChange()}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey={nameof('sizeClassGroupName')}
						label={{ value: 'Size Class (ac.)', position: 'insideBottom', offset: 0 }}
					/>
					<YAxis
						tickFormatter={(tick: number) => {
							return largeNumber(tick, true);
						}}
					/>
					{TooltipComponents.barAndAreaChartTooltip()}
					<Legend />
					<ReferenceLine y={0} stroke="#000" strokeWidth={2} />
					<Bar
						name={
							props.yValue === 'acres'
								? `${startingYear}-${endingYear} Acre Change`
								: `${startingYear}-${endingYear} Farm Change`
						}
						dataKey={props.yValue === 'acres' ? nameof('acres') : nameof('farms')}
						fill={greens[0]}
					/>
				</BarChart>
			</ResponsiveContainer>
		</>
	);
});
