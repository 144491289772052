import React, { useEffect, useState } from 'react';
import { DOMHelper } from 'Common/Helpers';
import { LinearProgress } from '@material-ui/core';
import { useCADEndpoint } from 'Endpoints';
import { CADDetails } from 'Models/CADModels';
import { DetailsPageTemplate } from 'Common/PageTemplates';
import { RouteComponentProps } from 'react-router-dom';

export const PopulousCounties = (props: RouteComponentProps<{ id: string }>) => {
	const ep = useCADEndpoint();
	const [county, setCounty] = useState<CADDetails>();
	const title = 'Populous Counties';

	useEffect(() => {
		DOMHelper.setPageTitle(title);
		ep.GetDetails(
			'el-paso,lubbock,webb,hidalgo,cameron,bexar,guadalupe,hays,travis,williamson,bell,parker,tarrant,dallas,denton,collin,ellis,kaufman,rockwall,brazos,montgomery,harris,fort-bend,brazoria,galveston'
		).then(r => {
			if (r !== undefined) {
				setCounty(r);
			}
		});
	}, [props.location]);

	if (!county || ep.IsLoading) {
		return <LinearProgress />;
	}

	return (
		<DetailsPageTemplate
			key={title}
			details={county}
			title={title}
			areaType="County"
			subtitle={`${county.names.sort((a, b) => a.localeCompare(b)).join(', ')} Counties`}
		/>
	);
};
