import { useEffect } from 'react';
import { loadModules, loadCss } from 'esri-loader';
import { grayGreen5, grayGreen10, grayGreen8, grayGreen6, redGrayGreen7 } from 'Common/Colors';
import settings from 'settings';

const options = {
	// tell Dojo where to load other packages
	dojoConfig: {
		packages: [
			{
				name: 'react',
				location: 'react',
			},
			{
				name: 'react-dom',
				location: 'react-dom',
			},
		],
	},
};

export interface IClassBreakFeatureLayerProps {
	url: string;
	map?: __esri.Map;
	view?: __esri.MapView;
	year: string;
	attributePrefix: string;
}

export const ClassBreakFeatureLayer = (props: IClassBreakFeatureLayerProps) => {
	// map and view are undefined above and asserted undefined below since the esri Map component passes both automatically
	// this was the best way to get that working and avoid lots of any's
	// https://github.com/Esri/react-arcgis/blob/master/src/ts/components/ArcBase.tsx#L92-L99
	const map = props.map!;
	const view = props.view!;
	let featureLayer: __esri.FeatureLayer;

	const MakeSqlChange = () => {
		const years = settings.displayYears;
		const start = years[0].toString().substring(2, 4);
		const end = years.slice(-1)[0].toString().substring(2, 4);
		return `(${props.attributePrefix}_${end} - ${props.attributePrefix}_${start})`;
	};

	const MakeArcadeChange = () => {
		const years = settings.displayYears;
		const start = years[0].toString().substring(2, 4);
		const end = years.slice(-1)[0].toString().substring(2, 4);
		return `$feature.${props.attributePrefix}_${end} - $feature.${props.attributePrefix}_${start}`;
	};

	useEffect(() => {
		loadCss();

		loadModules(
			[
				'esri/layers/FeatureLayer',
				'esri/Color',
				'esri/smartMapping/renderers/color',
				'esri/renderers/ClassBreaksRenderer',
				'esri/smartMapping/statistics/classBreaks',
			],
			options
		).then(
			([EsriFeatureLayer, Color, color, ClassBreaksRenderer, classBreaks]: [
				__esri.FeatureLayerConstructor,
				__esri.ColorConstructor,
				__esri.color,
				__esri.ClassBreaksRendererConstructor,
				(params: __esri.classBreaksClassBreaksParams) => Promise<__esri.ClassBreaksResult>
			]) => {
				const noChangeThreshold = 100;
				featureLayer = new EsriFeatureLayer({
					url: props.url,
					outFields: ['*'],
					title: 'Working Lands',
				});
				map.add(featureLayer, 0);

				// explicitly set the class breaks since that's the only way to ensure
				// 0 is the middle, muted color
				const SetChangeRenderer = (lossBreaks: __esri.ClassBreak[], gainBreaks: __esri.ClassBreak[]) => {
					const changeSymbol = (color: string) => {
						return {
							type: 'simple-fill',
							color,
							style: 'solid',
							outline: {
								width: 0.2,
								color: '#444444',
							},
						};
					};

					const r = new ClassBreaksRenderer({
						valueExpression: MakeArcadeChange(),
						valueExpressionTitle: props.attributePrefix + ' Change (acres)',
						defaultLabel: 'no data',
						classBreakInfos: [
							{
								...lossBreaks[0],
								symbol: changeSymbol(redGrayGreen7[0]),
							},
							{
								...lossBreaks[1],
								symbol: changeSymbol(redGrayGreen7[1]),
							},
							{
								...lossBreaks[2],
								symbol: changeSymbol(redGrayGreen7[2]),
							},
							{
								minValue: noChangeThreshold * -1,
								maxValue: noChangeThreshold,
								symbol: changeSymbol(redGrayGreen7[3]),
								label: `${lossBreaks[2].maxValue} - ${gainBreaks[0].minValue}`,
							},
							{
								...gainBreaks[0],
								symbol: changeSymbol(redGrayGreen7[4]),
							},
							{
								...gainBreaks[1],
								symbol: changeSymbol(redGrayGreen7[5]),
							},
							{
								...gainBreaks[2],
								symbol: changeSymbol(redGrayGreen7[6]),
							},
						],
					});

					featureLayer.renderer = r;
					featureLayer.opacity = 1;
				};

				featureLayer.opacity = 0;

				if (props.year === 'Change') {
					// doing this the long way with queries then classBreaks
					// because I never could get sqlWhere of the classBreaks function to work.
					// it always returned the entire dataset, even with sqlWhere: '1=0'
					const lossQuery = featureLayer.createQuery();
					lossQuery.where = MakeSqlChange() + ' < ' + noChangeThreshold * -1;
					lossQuery.outFields = ['*'];
					lossQuery.returnGeometry = false;
					const lossBreaks = featureLayer.queryFeatures(lossQuery).then(r =>
						classBreaks({
							view,
							layer: featureLayer,
							features: r.features,
							valueExpression: MakeArcadeChange(),
							classificationMethod: 'natural-breaks',
							numClasses: 3,
						})
					);

					const gainQuery = featureLayer.createQuery();
					gainQuery.where = MakeSqlChange() + ' > ' + noChangeThreshold;
					gainQuery.outFields = ['*'];
					gainQuery.returnGeometry = false;
					const gainBreaks = featureLayer.queryFeatures(gainQuery).then(r =>
						classBreaks({
							view,
							layer: featureLayer,
							features: r.features,
							valueExpression: MakeArcadeChange(),
							classificationMethod: 'natural-breaks',
							numClasses: 3,
						})
					);

					Promise.all([lossBreaks, gainBreaks]).then(all =>
						SetChangeRenderer(all[0].classBreakInfos, all[1].classBreakInfos)
					);
				} else {
					var acreColorParams = {
						layer: featureLayer,
						view: view,
						valueExpression: `$feature.${props.attributePrefix}_${props.year.substring(2, 4)}`,
						valueExpressionTitle: `${props.attributePrefix} ${props.year} (acres)`,
						classificationMethod: 'natural-breaks',
						numClasses: 8,
						colorScheme: {
							id: 'gray-green',
							name: 'gray-green',
							tags: ['color-scheme'],
							theme: 'high-to-low',
							colors: grayGreen5.map(g => new Color(g)),
							colorsForClassBreaks: [
								{
									colors: grayGreen5.map(g => new Color(g)),
									numClasses: 5,
								},
								{
									colors: grayGreen6.map(g => new Color(g)),
									numClasses: 6,
								},
								{
									colors: grayGreen8.map(g => new Color(g)),
									numClasses: 8,
								},
								{
									colors: grayGreen10.map(g => new Color(g)),
									numClasses: 10,
								},
							],
							opacity: 1,
							noDataColor: new Color('#ffffff'),
							outline: {
								color: new Color('#444444'),
								width: 0.3,
							},
						} as __esri.ColorSchemeForPolygon,
					} as __esri.colorCreateClassBreaksRendererParams;

					color.createClassBreaksRenderer(acreColorParams).then(function (response) {
						featureLayer.renderer = response.renderer;
						setTimeout(() => (featureLayer.opacity = 1), 250);
					});
				}
			}
		);
		return function cleanup() {
			map.remove(featureLayer);
		};
	}, []);

	return null;
};
