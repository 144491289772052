import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { DOMHelper } from 'Common/Helpers';
import { useROIEndpoint } from 'Endpoints';
import { LinearProgress } from '@material-ui/core';
import { DetailsPageTemplate } from 'Common/PageTemplates';
import { ROIDetails } from 'Models/ROIModels';

export const ROI = (props: RouteComponentProps<{ id: string }>) => {
	const ep = useROIEndpoint();
	const [roi, setRoi] = useState<ROIDetails>();
	const [title, setTitle] = useState('');

	useEffect(() => {
		ep.GetDetails(props.match.params.id).then(r => {
			if (r !== undefined) {
				const t = `${r.regionNames.join(', ')} ${
					r.regionNames.length === 1 ? 'Region of Interest' : 'Regions of Interest'
				}`;
				setTitle(t);
				DOMHelper.setPageTitle(t);
				setRoi(r);
			}
		});
	}, [props.match.params.id]);

	if (!roi || ep.IsLoading) {
		return <LinearProgress />;
	}

	return (
		<DetailsPageTemplate
			key={title}
			details={roi}
			title={title}
			areaType="County"
			subtitle={`${roi.names.sort((a, b) => a.localeCompare(b)).join(', ')} Counties`}
		/>
	);
};
