import { useNotifications } from 'Common/Notifications';
import { Ecoregion, EcoregionDetails } from 'Models/EcoregionModels';
import { Endpoint } from './Endpoint';

export const useEcoregionEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('ecoregions');

	const Get = () =>
		ep.Get<Ecoregion[]>().catch(() => {
			useError('Error getting ecoregions');
			return [] as Ecoregion[];
		});

	const GetDetails = (id: string) =>
		ep.Get<EcoregionDetails>(id).catch(() => {
			useError('Error getting ecoregion details');
			return undefined;
		});

	return { Get, GetDetails, IsLoading: ep.IsLoading };
};
