import React from 'react';
import { FieldProps, getIn } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { TextField } from '@material-ui/core';

export interface IGlobalAutocompleteProps<T> extends FieldProps {
	label?: string;
	options: T[];
	value: T[];
}

/** Wraps the formik material ui autocomplete so we don't have to repeat all the error and helperText props */
export const GlobalAutocomplete = <T extends object>(props: IGlobalAutocompleteProps<T>) => {
	const { field, form } = props;
	const { name } = field;
	const { touched, errors } = form;
	const currentError = getIn(errors, name);
	const currentTouched = getIn(touched, name);
	const showError = currentTouched && !!currentError;

	return (
		<Autocomplete
			{...props}
			disableCloseOnSelect={true}
			renderInput={(params: AutocompleteRenderInputParams) => (
				<TextField
					{...params}
					label={props.label}
					error={showError}
					helperText={currentTouched && currentError}
				/>
			)}
		/>
	);
};
