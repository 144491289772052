import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import settings from 'settings';
import { HistoryTracker } from './';

export const GoogleAnalytics = () => {
	useEffect(() => {
		if (settings.googleAnalyticsId !== '') {
			ReactGA.initialize(settings.googleAnalyticsId);
		}
	}, []);

	return <HistoryTracker onChange={u => {}} />;
};
