import { greens } from 'Common/Colors';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { largeNumber } from 'Common/Helpers/TextHelper';
import { Population } from 'Models/DetailsModels';
import React, { MutableRefObject } from 'react';
import { Legend, Bar, BarChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts';
import settings from 'settings';
import { TooltipComponents } from './Common';

export interface IPopulationBarChartProps {
	data: Population[];
	innerRef?: MutableRefObject<any> | null;
}

export const PopulationBarChart = React.memo((props: IPopulationBarChartProps) => {
	const nameof = useNameof<Population>();
	const data = props.data.filter(p => settings.displayYears.includes(p.year)).sort((a, b) => a.year - b.year);

	return (
		<ResponsiveContainer width="99%" minHeight="250px">
			<BarChart
				ref={props.innerRef}
				width={500}
				height={400}
				data={data}
				margin={{
					top: 5,
					right: 5,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="year" />
				<YAxis
					tickFormatter={(tick: number) => {
						return largeNumber(tick, true);
					}}
				/>
				{TooltipComponents.barAndAreaChartTooltip()}
				<Legend />
				<Bar dataKey={nameof('value')} fill={greens[0]} name="Population" />
			</BarChart>
		</ResponsiveContainer>
	);
});
