import { AreaPrefix, CompareArea } from 'Models/AppModels';
import React, { createContext, Dispatch, useReducer, useContext } from 'react';
import { ActionType, createAction } from 'typesafe-actions';
import settings from 'settings';

// actions
const Actions = {
	ResetData: createAction('COMPARE/RESET_DATA')(),
	SetCADs: createAction('COMPARE/SET_AREAS')<CompareArea[] | string>(),
	AddCAD: createAction('COMPARE/ADD_AREA')<CompareArea>(),
	RemoveCAD: createAction('COMPARE/REMOVE_AREA')<string>(),
};

// state
export class CompareState {
	dataShown: boolean = false;
	areas: CompareArea[] = [];
	limitReached: boolean = false;
}

const ParseAreas = (areaString: string) => {
	const areas: CompareArea[] = [];
	// separate all the areas in the string
	// e.g. c_Brazos|r_Colorado to ['c_Brazos','r_Colorado']
	areaString.split('|').map(area => {
		// separate the area type prefix from the selected areas within that type
		// e.g. 'c_Brazos,Grimes' to ['c', 'Brazos,Grimes']
		const sectionParts = area.split('_');
		const sectionPrefix = sectionParts[0] as AreaPrefix;
		const sectionValues = sectionParts[1];
		areas.push(new CompareArea(sectionPrefix, sectionValues));
	});

	return areas;
};

// reducer
export const CompareReducer = (state: CompareState, action: ActionType<typeof Actions>): CompareState => {
	const compareLimit = settings.compareLimit;
	switch (action.type) {
		case 'COMPARE/RESET_DATA':
			return { ...state, dataShown: false, limitReached: false, areas: [] };
		case 'COMPARE/SET_AREAS':
			// get all the areas. May be more than the allowed limit
			const areas = typeof action.payload === 'string' ? ParseAreas(action.payload) : action.payload;
			// only get the first n items if longer than the allowed limit
			const areasToSet = areas.length > compareLimit ? areas.slice(0, compareLimit) : areas;
			return {
				...state,
				dataShown: state.dataShown || !!areasToSet.length,
				limitReached: areasToSet.length >= compareLimit,
				areas: [...areasToSet],
			};
		case 'COMPARE/ADD_AREA':
			const currentAreaCount = state.areas.length;
			if (currentAreaCount >= compareLimit) {
				return state;
			}
			return {
				...state,
				limitReached: currentAreaCount + 1 >= compareLimit,
				areas: [...state.areas, action.payload],
			};
		case 'COMPARE/REMOVE_AREA':
			return {
				...state,
				limitReached: state.areas.length - 1 >= compareLimit,
				areas: [...state.areas].filter(c => c.toString() !== action.payload),
			};

		default:
			return state;
	}
};

// init contexts
// they are separate to reduce unnecessary re-renders when state changes
const initialState = new CompareState();
const CompareStateContext = createContext(initialState);
// tslint:disable-next-line: no-empty
const CompareDispatchContext = createContext<Dispatch<ActionType<typeof Actions>>>(() => {});

const CompareProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(CompareReducer, initialState);

	return (
		<CompareStateContext.Provider value={state}>
			<CompareDispatchContext.Provider value={dispatch}>{children}</CompareDispatchContext.Provider>
		</CompareStateContext.Provider>
	);
};

const useCompareState = () => {
	return useContext(CompareStateContext);
};

const useCompareDispatch = () => {
	return useContext(CompareDispatchContext);
};

export { CompareProvider, useCompareState, useCompareDispatch };
