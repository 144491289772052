import { useNotifications } from 'Common/Notifications';
import { StatewideDetails } from 'Models/StatewideModels';
import { Endpoint } from './Endpoint';

export const useStatewideEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('statewide');

	const GetDetails = () =>
		ep.Get<StatewideDetails>().catch(() => {
			useError('Error getting statewide details');
			return undefined;
		});

	return { GetDetails, IsLoading: ep.IsLoading };
};
