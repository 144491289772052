import { AppBar, Grid, Tab, Tabs } from '@material-ui/core';
import { TabPanel } from 'Common/Elements';
import { useAppState } from 'Context/App';
import { AreaTabsOptions } from 'Models/AppModels';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CADForm, RiverBasinForm, EcoregionForm, ROIForm } from '.';

const AreaSelector = (props: RouteComponentProps<any>) => {
	const { cads, ecoregions, riverBasins, rois } = useAppState();
	const [selectedTab, setSelectedTab] = useState<AreaTabsOptions>(AreaTabsOptions.CAD);

	return (
		<Grid container={true}>
			<Grid item={true} xs={6} sm={5} md={5} lg={4}>
				<AppBar position="static" color="default" elevation={0} style={{ height: '100%' }}>
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={selectedTab}
						onChange={(event: React.ChangeEvent<{}>, newValue: number) => setSelectedTab(newValue)}
						aria-label="Area Tabs"
					>
						<Tab label="County" />
						<Tab label="River Basin" />
						<Tab label="Ecoregion" />
						<Tab label="ROI" />
					</Tabs>
				</AppBar>
			</Grid>
			<Grid item={true} xs={6} sm={7} md={7} lg={8}>
				<TabPanel value={selectedTab} index={AreaTabsOptions.CAD}>
					<CADForm
						counties={cads}
						onSubmit={c => props.history.push('/trends/county/' + c.map(x => x.id).join(','))}
					/>
				</TabPanel>
				<TabPanel value={selectedTab} index={AreaTabsOptions.RiverBasin}>
					<RiverBasinForm
						riverbasins={riverBasins}
						onSubmit={c => props.history.push('/trends/riverbasin/' + c.map(x => x.id).join(','))}
					/>
				</TabPanel>
				<TabPanel value={selectedTab} index={AreaTabsOptions.Ecoregion}>
					<EcoregionForm
						ecoregions={ecoregions}
						onSubmit={c => props.history.push('/trends/ecoregion/' + c.map(x => x.id).join(','))}
					/>
				</TabPanel>
				<TabPanel value={selectedTab} index={AreaTabsOptions.ROI}>
					<ROIForm
						rois={rois}
						onSubmit={c => props.history.push('/trends/roi/' + c.map(x => x.id).join(','))}
					/>
				</TabPanel>
			</Grid>
		</Grid>
		// <Link to="/trends/statewide">View statewide data</Link>
	);
};

export const RouterAreaSelector = withRouter(AreaSelector);
