import { greens } from 'Common/Colors';
import { useAppState } from 'Context/App';
import React, { useEffect, useState } from 'react';
import Joyride, { Step, CallBackProps } from 'react-joyride';

export interface IHelpProps {
	run: boolean;
	onTourEnd: () => void;
}

export const Help = (props: IHelpProps) => {
	const [run, setRun] = useState(false);
	const state = useAppState();

	useEffect(() => {
		setRun(props.run);
	}, [props.run]);

	const steps: Step[] = [
		{
			target: '.action-container',
			content:
				'Charts may be downloaded/exported using the download button. The remaining buttons allows you to toggle the contents of this card between a chart, table, and info about the supporting data.',
			disableBeacon: true,
			placement: 'top',
		},
		{
			target: '#map-card',
			content:
				'Map data may be changed by selecting the Landuse and Year dropdowns. Maps may be printed/exported using the Print button.',
			disableBeacon: true,
			placement: 'top',
			spotlightClicks: true,
		},
		{
			target: '#working-lands-chart .recharts-legend-wrapper',
			content: 'The legend of multi-series charts may be clicked to toggle the visibility of individual series.',
			disableBeacon: true,
			spotlightClicks: true,
		},
		{
			target: '#menu-button',
			content:
				"The left navigation bar may be collapsed using the 'hamburger' menu button. This can he helpful to allow the main content area more screen space.",
			disableBeacon: true, // makes the first step show on run
			placement: 'bottom-start',
		},
		{
			target: '#theme-button',
			content:
				'The website theme may be toggled using this button. Your preference will be saved for later visits to the site.',
			disableBeacon: true,
			spotlightClicks: true,
		},
		{
			target: '#user-guide-link',
			content:
				'A more comprehensive user guide may be found here. It contains detailed site instructions as well as data definitions.',
			disableBeacon: true,
			placement: 'top-start',
		},
	];

	const handleJoyrideCallback = (data: CallBackProps) => {
		if (['stop', 'reset', 'close'].includes(data.action)) {
			props.onTourEnd();
			setRun(false);
		}
	};

	return (
		<Joyride
			steps={steps}
			scrollToFirstStep={true}
			continuous={true}
			run={run}
			callback={handleJoyrideCallback}
			floaterProps={{
				styles: {
					wrapper: {
						zIndex: 1500,
					},
				},
			}}
			styles={{
				options: {
					zIndex: 1500,
					arrowColor: state.Theme === 'light' ? 'white' : '#424242',
					backgroundColor: state.Theme === 'light' ? 'white' : '#424242',
					textColor: state.Theme === 'light' ? 'rgba(0,0,0,.87)' : 'white',
				},
				buttonNext: {
					backgroundColor: greens[0],
				},
				buttonBack: {
					color: state.Theme === 'light' ? greens[0] : 'white',
				},
			}}
		/>
	);
};
