import { MarketValueLineChart } from 'Common/Charts';
import { ChartCard } from 'Common/Elements';
import { LanduseTable } from 'Common/Tables';
import { ChartCardView } from 'Models/ChartModels';
import { Landuse } from 'Models/DetailsModels';
import React from 'react';

export interface IWorkingLandsMVChartCardProps {
	data: Landuse[];
}

export const WorkingLandsMVChartCard = (props: IWorkingLandsMVChartCardProps) => {
	return (
		<ChartCard title="Working Lands" subtitle="Market Value ($/ac)">
			{(selectedView, chartRef) => {
				if (selectedView === ChartCardView.Chart) {
					return <MarketValueLineChart data={props.data} innerRef={chartRef} />;
				}

				if (selectedView === ChartCardView.Table) {
					return <LanduseTable data={props.data} displayData="mv" />;
				}

				return (
					<>
						<p>
							The Texas Comptroller of Public Accounts (Texas Property Tax Assistance Division) produces
							an annual compilation of land use and land value data from all ISDs. This dataset represents
							all private lands designated as 1-D and 1-D-1 appraisal status for all Texas ISDs. A 1-D
							agricultural use (Assessments of Lands Designated for Agricultural Use) status refers to
							lands devoted to full time agricultural operations where the owner’s primary occupation and
							source of income is derived from agricultural enterprises.
						</p>
						<p>
							<a href="https://txlandtrends.org/about-the-data" target="_blank">
								About the data
							</a>
						</p>
					</>
				);
			}}
		</ChartCard>
	);
};
