import React from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from '@material-ui/core';
import { Landuse, landuseOrder } from 'Models/DetailsModels';
import { TextHelper, TableHelper } from 'Common/Helpers';
import useStyles from './TableStyles';
import settings from 'settings';
import { ChangeTooltip } from 'Common/Elements';

interface ILanduseTableProps {
	data: Landuse[];
	displayData: 'acres' | 'mv';
}

export const LanduseTable = (props: ILanduseTableProps) => {
	const classes = useStyles();
	const filtered = props.data.filter(d => settings.displayYears.includes(d.year));
	const pivoted = TableHelper.pivotLanduse(filtered).sort((a, b) => a.year - b.year);

	const calcChange = (landuseGroup: string) => {
		const start = pivoted
			.find(d => d.year === settings.displayYears[0])
			?.values.find(v => v.landuseGroupName === landuseGroup);

		const end = pivoted
			.find(d => d.year === settings.displayYears.slice(-1)[0])
			?.values.find(v => v.landuseGroupName === landuseGroup);

		if (props.displayData === 'acres') {
			return TextHelper.largeNumber((end?.acres ?? 0) - (start?.acres ?? 0));
		} else {
			const startAvg = start?.acres ?? 0 > 0 ? (start?.mv ?? 0) / (start?.acres ?? 0) : 0;
			const endAvg = end?.acres ?? 0 > 0 ? (end?.mv ?? 0) / (end?.acres ?? 0) : 0;
			return TextHelper.money(endAvg - startAvg);
		}
	};

	const calcTotalChange = () => {
		const start = props.data
			.filter(d => d.year === settings.displayYears[0])
			.reduce((acc, v) => ({ ...acc, mv: acc.mv += v.mv, acres: acc.acres += v.acres }), { mv: 0, acres: 0 });
		const end = props.data
			.filter(d => d.year === settings.displayYears.slice(-1)[0])
			.reduce((acc, v) => ({ ...acc, mv: acc.mv += v.mv, acres: acc.acres += v.acres }), { mv: 0, acres: 0 });

		if (props.displayData === 'acres') {
			return TextHelper.largeNumber(end.acres - start.acres);
		} else {
			const startAvg = start.acres > 0 ? start.mv / start.acres : 0;
			const endAvg = end.acres > 0 ? end.mv / end.acres : 0;
			return TextHelper.money(endAvg - startAvg);
		}
	};

	return (
		<Box className={classes.root}>
			<Table className={classes.table} size="small">
				<TableHead>
					<TableRow>
						<TableCell className={classes.noWrap}>Year</TableCell>
						<TableCell className={classes.noWrap}>Cropland</TableCell>
						<TableCell className={classes.noWrap}>Grazing Land</TableCell>
						<TableCell className={classes.noWrap}>Other</TableCell>
						<TableCell className={classes.noWrap}>Timber</TableCell>
						<TableCell className={classes.noWrap}>Wildlife Management</TableCell>
						<TableCell className={classes.noWrap}>
							{props.displayData === 'acres' ? 'Total' : 'Average'}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{pivoted.map(val => {
						const yearTotal = val.values.reduce(
							(acc, v) => ({ ...acc, mv: acc.mv += v.mv, acres: acc.acres += v.acres }),
							{ mv: 0, acres: 0 }
						);
						return (
							<TableRow key={val.year}>
								<TableCell>{val.year}</TableCell>
								{val.values
									.sort(
										(a, b) =>
											landuseOrder.indexOf(a.landuseGroupName) -
											landuseOrder.indexOf(b.landuseGroupName)
									)
									.map(v => {
										return props.displayData === 'acres' ? (
											<TableCell key={v.landuseGroupName}>
												{TextHelper.largeNumber(v.acres)}
											</TableCell>
										) : (
											<TableCell key={v.landuseGroupName} align="left">
												{TextHelper.money(v.mv / v.acres)}
											</TableCell>
										);
									})}
								<TableCell>
									{props.displayData === 'acres'
										? TextHelper.largeNumber(yearTotal.acres)
										: TextHelper.money(yearTotal.mv / yearTotal.acres)}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell>
							<ChangeTooltip>Change</ChangeTooltip>
						</TableCell>
						<TableCell>{calcChange(landuseOrder[0])}</TableCell>
						<TableCell>{calcChange(landuseOrder[1])}</TableCell>
						<TableCell>{calcChange(landuseOrder[2])}</TableCell>
						<TableCell>{calcChange(landuseOrder[3])}</TableCell>
						<TableCell>{calcChange(landuseOrder[4])}</TableCell>
						<TableCell>{calcTotalChange()}</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</Box>
	);
};
