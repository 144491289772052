import { Grid, LinearProgress } from '@material-ui/core';
import {
	WorkingLandsAcresChartCard,
	WorkingLandsMVChartCard,
	PopulationChartCard,
	OwnershipAcresChartCard,
	OwnershipFarmsChartCard,
	WorkingLandsKeyMetricCard,
	MarketValueKeyMetricCard,
	PopulationKeyMetricCard,
} from 'Common/Cards';
import { Header4 } from 'Common/Elements/Headers';
import { useCADEndpoint, useEcoregionEndpoint, useRiverBasinEndpoint, useROIEndpoint } from 'Endpoints';
import React, { useEffect, useState } from 'react';
import { AreaPrefix, CompareArea } from 'Models/AppModels';
import { AreaType, DetailsBase } from 'Models/DetailsModels';
import { CompareSelector } from './CompareSelector';
import { OverviewFeatureLayer, Map, MapCard } from 'Mapping';
import { CardBackground, DeleteIcon } from 'Common/Elements';

export interface IAreaColumnProps {
	area?: CompareArea;
	onDeleteClick?: (area?: CompareArea) => void;
}

export const AreaColumn = React.memo((props: IAreaColumnProps) => {
	const cadEp = useCADEndpoint();
	const ecoregionEp = useEcoregionEndpoint();
	const riverBasinEp = useRiverBasinEndpoint();
	const roiEp = useROIEndpoint();
	const [areaType, setAreaType] = useState<AreaType>();
	const [area, setArea] = useState<DetailsBase>();

	useEffect(() => {
		if (props.area) {
			switch (props.area.areaPrefix) {
				case AreaPrefix.CAD:
					cadEp.GetDetails(props.area.areas).then(r => {
						if (r !== undefined) {
							setArea(r);
							setAreaType('County');
						}
					});
					break;
				case AreaPrefix.Ecoregion:
					ecoregionEp.GetDetails(props.area.areas).then(r => {
						if (r !== undefined) {
							setArea(r);
							setAreaType('Ecoregion');
						}
					});
					break;
				case AreaPrefix.RiverBasin:
					riverBasinEp.GetDetails(props.area.areas).then(r => {
						if (r !== undefined) {
							setArea(r);
							setAreaType('RiverBasin');
						}
					});
					break;
				case AreaPrefix.ROI:
					roiEp.GetDetails(props.area.areas).then(r => {
						if (r !== undefined) {
							setArea(r);
							setAreaType('County');
						}
					});
					break;
			}
		} else {
			setArea(undefined);
		}
	}, [props.area]);

	const titleSuffix = () => {
		if (!props.area) {
			return '';
		}

		switch (props.area.areaPrefix) {
			case AreaPrefix.CAD:
				return 'County';
			case AreaPrefix.Ecoregion:
				return 'Ecoregion';
			case AreaPrefix.RiverBasin:
				return 'River Basin';
			case AreaPrefix.ROI:
				return 'Region of Interest';
		}
	};

	const handleDeleteClick = () => {
		setArea(undefined);
		props.onDeleteClick && props.onDeleteClick(props.area);
	};

	// show loading bar if the area is loading
	if (cadEp.IsLoading || ecoregionEp.IsLoading || riverBasinEp.IsLoading || roiEp.IsLoading) {
		return (
			<Grid item={true} md={true}>
				<LinearProgress />
			</Grid>
		);
	}

	// show data if we have a defined area
	if (area) {
		return (
			<>
				<Grid item={true} md={true}>
					<Grid container={true}>
						<Grid item={true} md={12}>
							<Header4
								title={
									<>
										<DeleteIcon onClick={handleDeleteClick} />
										{`${area.names.join(', ')} ${titleSuffix()}`}
									</>
								}
							/>
						</Grid>
						<Grid item={true} xs={12}>
							<CardBackground style={{ height: 200 }}>
								<Map initialZoom={3}>
									<OverviewFeatureLayer areaType={areaType} names={area.names} zoomTo={true} />
								</Map>
							</CardBackground>
						</Grid>
						<Grid item={true} xs={12} style={{ flexBasis: 275, flexGrow: 1 }}>
							<WorkingLandsKeyMetricCard data={area.landuse} />
						</Grid>
						<Grid item={true} xs={12} style={{ flexBasis: 275, flexGrow: 1 }}>
							<MarketValueKeyMetricCard data={area.landuse} />
						</Grid>
						<Grid item={true} xs={12} style={{ flexBasis: 274, flexGrow: 1 }}>
							<PopulationKeyMetricCard data={area.population} />
						</Grid>
						{
							// I don't know why setting width to 0 works, but it allows charts to scale smaller fast
							// https://github.com/recharts/recharts/issues/172#issuecomment-240036906
						}
						<Grid item={true} xs={12} style={{ width: 0 }}>
							<WorkingLandsAcresChartCard data={area.landuse} />
						</Grid>
						<Grid item={true} xs={12} style={{ width: 0 }}>
							<WorkingLandsMVChartCard data={area.landuse} />
						</Grid>
						<Grid item={true} xs={12} style={{ width: 0 }}>
							<PopulationChartCard data={area.population} />
						</Grid>
						<Grid item={true} xs={12} style={{ width: 0 }}>
							<OwnershipAcresChartCard data={area.ownership} />
						</Grid>
						<Grid item={true} xs={12} style={{ width: 0 }}>
							<OwnershipFarmsChartCard data={area.ownership} />
						</Grid>
						<Grid item={true} xs={12} style={{ width: 0 }}>
							<MapCard>
								<OverviewFeatureLayer areaType={areaType} names={area.names} color={[0, 0, 0, 1]} />
							</MapCard>
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	}

	// show area picker if we don't have an area
	return (
		<Grid
			item={true}
			md={true}
			style={{
				borderRadius: 5,
				border: 'dashed 5px #ccc',
				margin: -5,
			}}
		>
			<CompareSelector style={{ margin: '20% auto 0 auto', maxWidth: '80%' }} />
		</Grid>
	);
});
