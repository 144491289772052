import React from 'react';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export interface IDeleteIconProps {
	onClick?: () => void;
	isDisabled?: boolean;
}

const useStyles = makeStyles(theme => ({
	actionIcon: {
		cursor: 'pointer',
		fontSize: '21px',
		marginRight: theme.spacing(1),
	},
	deleteIcon: {
		color: red[500],
	},
	deleteIconNotAllowed: {
		opacity: 0.4,
		cursor: 'not-allowed',
	},
}));

export const DeleteIcon = (props: IDeleteIconProps) => {
	const classes = useStyles();
	const deleteIconClass = props.isDisabled ? classes.deleteIconNotAllowed : '';

	return (
		<DeleteOutlineIcon
			className={`${classes.actionIcon} ${classes.deleteIcon} ${deleteIconClass}`}
			onClick={() => {
				if (props.isDisabled !== true && props.onClick) {
					props.onClick();
				}
			}}
		/>
	);
};
