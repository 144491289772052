import { useEffect } from 'react';
import { loadModules, loadCss } from 'esri-loader';

const options = {
	// tell Dojo where to load other packages
	dojoConfig: {
		packages: [
			{
				name: 'react',
				location: 'react',
			},
			{
				name: 'react-dom',
				location: 'react-dom',
			},
		],
	},
};

export interface IImageryLayerProps {
	url: string;
}

export const ImageryLayer = (props: IImageryLayerProps | any) => {
	useEffect(() => {
		loadCss();

		loadModules(['esri/layers/ImageryLayer'], options).then(([EsriImageryLayer]) => {
			const imageryLayer: __esri.ImageryLayer = new EsriImageryLayer({
				url: props.url,
			});

			props.map.add(imageryLayer);
		});
	}, []);

	return null;
};
