import React, { CSSProperties } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface ICardBackgroundProps {
	style?: CSSProperties;
}

const useStyles = makeStyles(() => ({
	card: {
		padding: 0,
	},
	content: {
		padding: '0 !important',
	},
}));

export const CardBackground: React.FC<ICardBackgroundProps> = props => {
	const classes = useStyles();

	return (
		<Card className={classes.card} style={props.style}>
			<CardContent className={classes.content} style={{ height: '100%' }}>
				{props.children}
			</CardContent>
		</Card>
	);
};
