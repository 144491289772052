import React from 'react';

import classnames from 'classnames';
import {
	AppBar as MuiAppBar,
	Toolbar,
	Hidden,
	IconButton,
	Typography,
	Tooltip,
	Menu,
	MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import LightIcon from '@material-ui/icons/Brightness7';
import DarkIcon from '@material-ui/icons/Brightness4';
import HelpIcon from '@material-ui/icons/HelpRounded';
import { useAppState, useAppDispatch } from 'Context/App';

export interface IAppBarProps {
	isMenuExpanded: boolean;
	onAddClick?: () => void;
	onMobileMenuClick: () => void;
	onDesktopMenuClick: () => void;
	onHelpClick: () => void;
}

const useStyles = makeStyles(theme => ({
	toolbar: {
		justifyContent: 'space-between',
	},
	mobileMenuContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	sectionDesktop: {
		display: 'none',
		justifyContent: 'flex-end',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			paddingLeft: 235,
			transition: 'all .35s ease-in',
			width: '100%',
		},
		[theme.breakpoints.up('lg')]: {
			justifyContent: 'space-between',
		},
	},
	sectionDesktopCollapsed: {
		[theme.breakpoints.up('md')]: {
			paddingLeft: 50,
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
}));

export const AppBar = (props: IAppBarProps) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(undefined);

	const isMenuOpen = Boolean(anchorEl);

	const state = useAppState();
	const dispatch = useAppDispatch();

	function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
		setAnchorEl(event.currentTarget);
	}

	function handleMenuClose() {
		setAnchorEl(undefined);
	}

	return (
		<div>
			<MuiAppBar position="fixed">
				<Toolbar className={classes.toolbar}>
					<Hidden lgUp={true} implementation="css">
						<div className={classes.mobileMenuContainer}>
							<IconButton color="inherit" aria-label="Open drawer" onClick={props.onMobileMenuClick}>
								<MenuIcon />
							</IconButton>
							<Typography variant="h6" color="inherit" noWrap={true}>
								Texas Land Trends
							</Typography>
						</div>
					</Hidden>

					<React.Fragment>
						<div
							className={classnames(
								classes.sectionDesktop,
								!props.isMenuExpanded && classes.sectionDesktopCollapsed
							)}
						>
							<Hidden mdDown={true} implementation="css">
								<IconButton
									color="inherit"
									aria-label="Collapse menu"
									onClick={props.onDesktopMenuClick}
									id="menu-button"
								>
									<MenuIcon />
								</IconButton>
							</Hidden>
							<div>
								<IconButton
									color="inherit"
									aria-label="Help"
									onClick={props.onHelpClick}
									id="help-button"
								>
									<HelpIcon />
								</IconButton>
								{state.Theme === 'light' ? (
									<IconButton
										onClick={() => dispatch({ type: 'APP/SET_THEME', payload: 'dark' })}
										color="inherit"
										size="small"
										className={classes.menuButton}
										id="theme-button"
									>
										<Tooltip title="Dark Mode">
											<DarkIcon />
										</Tooltip>
									</IconButton>
								) : (
									<IconButton
										onClick={() => dispatch({ type: 'APP/SET_THEME', payload: 'light' })}
										color="inherit"
										size="small"
										className={classes.menuButton}
										id="theme-button"
									>
										<Tooltip title="Light Mode">
											<LightIcon />
										</Tooltip>
									</IconButton>
								)}
							</div>
						</div>
						<div className={classes.sectionMobile}>
							<IconButton aria-haspopup="true" onClick={handleMenuOpen} color="inherit">
								<MoreIcon />
							</IconButton>
						</div>
					</React.Fragment>
				</Toolbar>
			</MuiAppBar>
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={isMenuOpen}
				onClose={handleMenuClose}
			>
				{state.Theme === 'light' ? (
					<MenuItem onClick={() => dispatch({ type: 'APP/SET_THEME', payload: 'dark' })}>Dark Mode</MenuItem>
				) : (
					<MenuItem onClick={() => dispatch({ type: 'APP/SET_THEME', payload: 'light' })}>
						Light Mode
					</MenuItem>
				)}
			</Menu>
		</div>
	);
};
