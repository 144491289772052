import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			overflowX: 'auto',
		},
		table: {
			minWidth: 300,
		},
		noWrap: {
			whiteSpace: 'nowrap',
		},
	})
);

export default useStyles;
