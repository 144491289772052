import { ordinal } from 'Common/Colors';
import { pivotLanduse } from 'Common/Helpers/ArrayHelper';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { money } from 'Common/Helpers/TextHelper';
import { Landuse, PivotedLanduse } from 'Models/DetailsModels';
import React, { MutableRefObject, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, YAxis } from 'recharts';
import settings from 'settings';
import { ChartComponents } from './Common';
import { TooltipComponents } from './Common';

export interface IMarketValueLineChartProps {
	data: Landuse[];
	innerRef?: MutableRefObject<any> | null;
}

export const MarketValueLineChart = React.memo((props: IMarketValueLineChartProps) => {
	const nameof = useNameof<PivotedLanduse>();
	const pivoted = pivotLanduse(
		props.data.filter(d => settings.displayYears.includes(d.year)),
		d => (d.acres !== 0 ? d.mv / d.acres : 0)
	);
	const [currentSeries, setCurrentSeries] = useState<string>();
	const seriesOpacity = (seriesKey: string) => {
		return currentSeries === seriesKey ? 1 : 0.3;
	};

	const handleMouseOver = (seriesKey: string) => {
		setCurrentSeries(seriesKey);
	};
	const handleMouseOut = () => {
		setCurrentSeries(undefined);
	};

	const [disabledSeries, setDisabledSeries] = useState<string[]>([]);

	const toggleSeries = (dataKey: string) => {
		setDisabledSeries(series =>
			disabledSeries.includes(dataKey) ? series.filter(s => s !== dataKey) : [...series, dataKey]
		);
	};

	const getWorkingLandsAcres = (year: number) => {
		return props.data.filter(l => l.year === year).reduce((acc, l) => acc + l.acres, 0);
	};
	const getMV = (year: number) => {
		const totalMV = props.data.filter(l => l.year === year).reduce((acc, l) => acc + l.mv, 0);
		const totalAcres = getWorkingLandsAcres(year);

		return totalMV / totalAcres;
	};

	return (
		<ResponsiveContainer width="99%" minHeight="250px">
			<LineChart
				ref={props.innerRef}
				width={600}
				height={400}
				data={pivoted}
				margin={{
					top: 20,
					right: 20,
					bottom: 20,
					left: 20,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				{ChartComponents.enhancedXAxis({ dataKey: 'year' })}
				<YAxis
					tickFormatter={(tick: number) => {
						return money(tick);
					}}
				/>
				{TooltipComponents.lineChartTooltip({ seriesKey: currentSeries })}
				<Legend onClick={(e: any) => toggleSeries(e.dataKey)} />
				<Line
					type="monotone"
					dataKey={nameof('cropland')}
					stroke={ordinal[0]}
					name="Cropland"
					strokeOpacity={seriesOpacity(nameof('cropland'))}
					strokeWidth={1.5}
					activeDot={{
						onMouseOver: () => handleMouseOver(nameof('cropland')),
						onMouseOut: () => handleMouseOut(),
					}}
					hide={disabledSeries.includes(nameof('cropland'))}
				/>
				<Line
					type="monotone"
					dataKey={nameof('grazingLand')}
					stroke={ordinal[1]}
					name="Grazing Land"
					strokeOpacity={seriesOpacity(nameof('grazingLand'))}
					strokeWidth={1.5}
					activeDot={{
						onMouseOver: () => handleMouseOver(nameof('grazingLand')),
						onMouseOut: () => handleMouseOut(),
					}}
					hide={disabledSeries.includes(nameof('grazingLand'))}
				/>

				<Line
					type="monotone"
					dataKey={nameof('timber')}
					stroke={ordinal[2]}
					name="Timber"
					strokeOpacity={seriesOpacity(nameof('timber'))}
					strokeWidth={1.5}
					activeDot={{
						onMouseOver: () => handleMouseOver(nameof('timber')),
						onMouseOut: () => handleMouseOut(),
					}}
					hide={disabledSeries.includes(nameof('timber'))}
				/>
				<Line
					type="monotone"
					dataKey={nameof('wildlifeManagement')}
					stroke={ordinal[3]}
					name="Wildlife Management"
					strokeOpacity={seriesOpacity(nameof('wildlifeManagement'))}
					strokeWidth={1.5}
					activeDot={{
						onMouseOver: () => handleMouseOver(nameof('wildlifeManagement')),
						onMouseOut: () => handleMouseOut(),
					}}
					hide={disabledSeries.includes(nameof('wildlifeManagement'))}
				/>
				<Line
					type="monotone"
					dataKey={nameof('other')}
					stroke={ordinal[4]}
					name="Other"
					strokeOpacity={seriesOpacity(nameof('other'))}
					strokeWidth={1.5}
					activeDot={{
						onMouseOver: () => handleMouseOver(nameof('other')),
						onMouseOut: () => handleMouseOut(),
					}}
					hide={disabledSeries.includes(nameof('other'))}
				/>
				<Line
					type="monotone"
					dataKey={(d: PivotedLanduse) => getMV(d.year)}
					stroke={ordinal[5]}
					name="Average"
					strokeWidth={2}
					activeDot={{ onMouseOver: () => handleMouseOver('Average'), onMouseOut: () => handleMouseOut() }}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
});
