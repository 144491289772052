import React from 'react';
import GroupIcon from '@material-ui/icons/Group';
import settings from 'settings';
import { KeyMetricCard } from 'Common/Elements';
import { Population } from 'Models/DetailsModels';

export interface IPopulationKeyMetricCardProps {
	data: Population[];
}

export const PopulationKeyMetricCard = (props: IPopulationKeyMetricCardProps) => {
	const startingYear = settings.displayYears[0];
	const [endingYear] = settings.displayYears.slice(-1);

	return props.data.length ? (
		<KeyMetricCard
			title="Population Change"
			startingvalue={props.data.find(p => p.year === startingYear)?.value}
			endingvalue={props.data.find(p => p.year === endingYear)?.value}
			icon={<GroupIcon />}
			iconColors={['rgb(77, 104, 125)', 'rgb(47, 63, 76)']}
			delay={300}
		/>
	) : null;
};
