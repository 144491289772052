import React from 'react';
import { Field } from 'formik';
import { GlobalAutocomplete } from '.';
import { RiverBasin } from 'Models/RiverBasinModels';

export interface IRiverBasinSelectProps {
	/** The array of partners that will be shown as dropdown items */
	options: RiverBasin[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the nameof generic (e.g. nameof<MyModel>('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	multiple?: boolean;
	/** For controlled autocomplete, if no value is provided it will be an empty RiverBasin model */
	value?: RiverBasin;
}

export const RiverBasinSelect = (props: IRiverBasinSelectProps) => {
	return (
		<Field
			name={props.name}
			key="CAD-auto"
			component={(p: any) => (
				<GlobalAutocomplete
					{...p}
					label={props.label || 'River Basins'}
					options={props.options.sort((a, b) => a.name.localeCompare(b.name))}
					multiple={props.multiple}
				/>
			)}
			getOptionLabel={(option: RiverBasin) => option.name}
			getOptionSelected={(option: RiverBasin, value: RiverBasin) => value.id === option.id}
			fullWidth={true}
			filterSelectedOptions={true}
		/>
	);
};
