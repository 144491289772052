import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { StyledCard } from 'Common/Elements';
import { LanduseValue } from 'Models/DetailsModels';
import React, { useState } from 'react';
import { ClassBreakFeatureLayer, Map, MapLegend, Print } from './';

export const MapCard: React.FC = props => {
	const [landuse, setLanduse] = useState<LanduseValue>('Cropland');
	const [year, setYear] = useState('2017');

	const ShowFeatureLayer = () => {
		const yearSub = year.substring(2, 4);
		const key = landuse + yearSub;
		switch (landuse) {
			case 'Cropland':
				return (
					<ClassBreakFeatureLayer
						key={key}
						year={year}
						attributePrefix="Crop"
						url="https://gis.nri.tamu.edu/arcgis/rest/services/LandTrends/LTLayers/MapServer/6"
					/>
				);
			case 'Grazing Land':
				return (
					<ClassBreakFeatureLayer
						key={key}
						year={year}
						attributePrefix="Grazing"
						url="https://gis.nri.tamu.edu/arcgis/rest/services/LandTrends/LTLayers/MapServer/6"
					/>
				);
			case 'Other':
				return (
					<ClassBreakFeatureLayer
						key={key}
						year={year}
						attributePrefix="Other"
						url="https://gis.nri.tamu.edu/arcgis/rest/services/LandTrends/LTLayers/MapServer/6"
					/>
				);
			case 'Timber':
				return (
					<ClassBreakFeatureLayer
						key={key}
						year={year}
						attributePrefix="Timber"
						url="https://gis.nri.tamu.edu/arcgis/rest/services/LandTrends/LTLayers/MapServer/6"
					/>
				);
			case 'Wildlife Management':
				return (
					<ClassBreakFeatureLayer
						key={key}
						year={year}
						attributePrefix="WM"
						url="https://gis.nri.tamu.edu/arcgis/rest/services/LandTrends/LTLayers/MapServer/6"
					/>
				);
			default:
				return (
					<ClassBreakFeatureLayer
						key={key}
						year={year}
						attributePrefix="Total"
						url="https://gis.nri.tamu.edu/arcgis/rest/services/LandTrends/LTLayers/MapServer/6"
					/>
				);
		}
	};

	return (
		<StyledCard
			contentStyle={{ height: 350, padding: 0 }}
			title={
				<div>
					<FormControl>
						<InputLabel id="demo-simple-select-label">Landuse</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={landuse}
							onChange={(e: React.ChangeEvent<{ value: LanduseValue }>) => setLanduse(e.target.value)}
						>
							<MenuItem value="Cropland">Cropland</MenuItem>
							<MenuItem value="Grazing Land">Grazing Land</MenuItem>
							<MenuItem value="Other">Other</MenuItem>
							<MenuItem value="Timber">Timber</MenuItem>
							<MenuItem value="Wildlife Management">Wildife Management</MenuItem>
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel id="demo-simple-select-label">Year</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={year}
							onChange={(e: React.ChangeEvent<{ value: string }>) => setYear(e.target.value)}
						>
							<MenuItem value="1997">1997</MenuItem>
							<MenuItem value="2002">2002</MenuItem>
							<MenuItem value="2007">2007</MenuItem>
							<MenuItem value="2012">2012</MenuItem>
							<MenuItem value="2017">2017</MenuItem>
							<MenuItem value="Change">Change</MenuItem>
						</Select>
					</FormControl>
				</div>
			}
		>
			<Map initialZoom={5} allowNavigation={true}>
				<Print defaultTitle={`${landuse} ${year}`} />
				<MapLegend />

				{ShowFeatureLayer()}
				{props.children}
			</Map>
		</StyledCard>
	);
};
