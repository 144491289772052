import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useCompareDispatch, useCompareState } from 'Context/Compare';
import { AreaColumn } from 'Compare';
import { FixedFab } from 'Common/Elements';
import AddIcon from '@material-ui/icons/Add';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import settings from 'settings';
import { CompareArea } from 'Models/AppModels';
import { DOMHelper } from 'Common/Helpers';

const Compare = (props: RouteComponentProps<{ id: string }>) => {
	const state = useCompareState();
	const dispatch = useCompareDispatch();
	const minToShow = 2;
	const [numToShow, setNumToShow] = useState(['blah', 'blah']);

	// set areas to state
	// this assumees someone comes to the site via this URL
	useEffect(() => {
		DOMHelper.setPageTitle('Compare Areas');
		if (props.match.params.id) {
			dispatch({ type: 'COMPARE/SET_AREAS', payload: props.match.params.id });
		}
	}, []);

	// show more than the default two columns if there are more areas selected than that
	// also update the URL so it's current and in a sharable state
	useEffect(() => {
		if (state.areas.length > minToShow) {
			setNumToShow(state.areas.map(() => 'blah'));
		} else {
			setNumToShow(['blah', 'blah']);
		}

		// for some reason, I couldn't add a method to the CompareState class to return a string
		// of areas. The array was always empty
		props.history.push(`/compare/${state.areas.map(a => `${a.areaPrefix}_${a.areas}`).join('|')}`);
	}, [state.areas]);

	const handleDeleteClick = (area?: CompareArea) => {
		if (area) {
			dispatch({ type: 'COMPARE/REMOVE_AREA', payload: area.toString() });
		}
	};

	return (
		<Grid container={true} style={{ height: '100%' }}>
			{
				// show empty areas in a way that's separate from manipulating compare state
				// super hacky way of doing this, but I could not get a for loop to work in the return statement
				// next best thing was just to make numToShow an array and call .map, so I could use the index param
			}
			{numToShow.map((v, i) => {
				return i < numToShow.length ? (
					<AreaColumn key={i} area={state.areas[i]} onDeleteClick={handleDeleteClick} />
				) : null;
			})}

			{!state.limitReached && numToShow.length < settings.compareLimit && (
				<FixedFab
					ariaLabel="add area"
					label="Add Area"
					icon={<AddIcon />}
					onClick={() => setNumToShow([...numToShow, 'blah'])}
				/>
			)}
		</Grid>
	);
};

export const CompareWithRouter = withRouter(Compare);
