import { Payload } from 'recharts/types/component/DefaultTooltipContent';

export enum ChartCardView {
	Chart = 0,
	Table = 1,
	Info = 2,
}
export interface IChartTooltipContent {
	active: boolean;
	payload?: Array<Payload<any, any>>;
}
