import { useEffect } from 'react';
import { loadModules, loadCss } from 'esri-loader';

const options = {
	// tell Dojo where to load other packages
	dojoConfig: {
		packages: [
			{
				name: 'react',
				location: 'react',
			},
			{
				name: 'react-dom',
				location: 'react-dom',
			},
		],
	},
};

export interface IFeatureLayerProps {
	url: string;
	filterNames?: string[];
	map?: __esri.Map;
	view?: __esri.MapView;
	applyRenderer?: boolean;
	/** rgba array */
	selectedFeatureColor?: number[];
	nameAttribute?: string;
	onFeatureClick?: (name: string) => void;
	order?: number;
	graphicOrder?: number;
	zoomTo?: boolean;
	legendEnabled?: boolean;
	title?: string;
}

export const FeatureLayer = (props: IFeatureLayerProps) => {
	// map and view are undefined above and asserted undefined below since the esri Map component passes both automatically
	// this was the best way to get that working and avoid lots of any's
	// https://github.com/Esri/react-arcgis/blob/master/src/ts/components/ArcBase.tsx#L92-L99
	const map = props.map!;
	const view = props.view!;
	useEffect(() => {
		loadCss();

		loadModules(
			[
				'esri/layers/FeatureLayer',
				'esri/layers/GraphicsLayer',
				'esri/Graphic',
				'esri/Color',
				'esri/renderers/SimpleRenderer',
				'esri/symbols/SimpleFillSymbol',
			],
			options
		).then(
			([EsriFeatureLayer, GraphicsLayer, Graphic, Color, SimpleRenderer, SimpleFillSymbol]: [
				__esri.FeatureLayerConstructor,
				__esri.GraphicsLayerConstructor,
				__esri.GraphicConstructor,
				__esri.ColorConstructor,
				__esri.SimpleRendererConstructor,
				__esri.SimpleFillSymbolConstructor
			]) => {
				const simpleRenderer = new SimpleRenderer({
					symbol: new SimpleFillSymbol({
						color: [0, 0, 0, 0],
						style: 'solid',
						outline: {
							width: 1,
							color: [0, 0, 0, 0.15],
						},
					}),
				});

				const graphicsLayer = new GraphicsLayer();

				const query = {
					// autocasts as Query
					// tslint:disable-next-line: quotemark
					where:
						props.filterNames && props.nameAttribute
							? `${props.nameAttribute} IN ('${props.filterNames.join("','")}')`
							: '1 = 0',
					returnGeometry: true,
					outFields: ['*'],
				};

				// api wasn't happy passing renderer as undefined. who knew.
				const defaultProps = {
					url: props.url,
					outFields: ['*'],
					legendEnabled: props.legendEnabled,
					title: props.title,
				};
				const featureLayer = new EsriFeatureLayer(
					props.applyRenderer
						? {
								...defaultProps,
								renderer: simpleRenderer,
						  }
						: defaultProps
				);

				const addGraphics = (result: __esri.FeatureSet) => {
					graphicsLayer.removeAll();
					const graphics = result.features.map(feature => {
						const g = new Graphic({
							geometry: feature.geometry,
							attributes: feature.attributes,
							symbol: new SimpleFillSymbol({
								color: [0, 0, 0, 0],
								style: 'solid',
								outline: {
									width: 1,
									color: props.selectedFeatureColor || [55, 191, 61, 1],
								},
							}),
						});
						graphicsLayer.add(g);
						return g;
					});

					props.zoomTo === true && view.goTo(graphics);
				};

				map.add(featureLayer, props.order);
				map.add(graphicsLayer, props.graphicOrder);

				view.whenLayerView(featureLayer).then(layerView => {
					layerView.watch('updating', value => {
						if (!value) {
							// wait for the layer view to finish updating
							// get all the features available for drawing.
							layerView.queryFeatures(query).then(results => {
								addGraphics(results);
							});
						}
					});

					view.on('click', event => {
						var q = {
							geometry: event.mapPoint,
							outFields: ['*'],
							returnGeometry: true,
							where: '1=1',
						};
						layerView.queryFeatures(q).then(results => {
							if (!results.features.length) {
								return;
							}
							var name = props.nameAttribute
								? (results.features[0].attributes[props.nameAttribute] as string)
								: undefined;
							// return the name of the feature that was clicked
							name && props.onFeatureClick && props.onFeatureClick(name);
						});
					});
				});
			}
		);
	}, []);

	return null;
};
