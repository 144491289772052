import { Button, FormControl, Grid } from '@material-ui/core';
import { EcoregionSelect } from './Fields';
import { Formik, Form } from 'formik';
import { Ecoregion } from 'Models/EcoregionModels';
import React from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

export interface IEcoregionFormProps {
	ecoregions: Ecoregion[];
	onSubmit: (ecoregions: Ecoregion[]) => void;
	buttonText?: string;
	disabled?: boolean;
}

class FormValues {
	ecoregions: Ecoregion[] = [];
}

export const EcoregionForm = (props: IEcoregionFormProps) => {
	const nameof = useNameof<FormValues>();

	return (
		<Formik
			initialValues={new FormValues()}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				values.ecoregions.length && props.onSubmit(values.ecoregions);
				setSubmitting(false);
				resetForm();
			}}
		>
			<Form>
				<Grid container={true}>
					<Grid item={true} xs={12}>
						<FormControl fullWidth={true}>
							<EcoregionSelect
								label="Select ecoregions"
								options={props.ecoregions}
								name={nameof('ecoregions')}
								multiple={true}
							/>
						</FormControl>
					</Grid>
					<Grid item={true} md={8} />
					<Grid item={true} md={4}>
						<Button
							fullWidth={true}
							type="submit"
							style={{ marginLeft: 12, marginTop: 12 }}
							disabled={props.disabled}
						>
							{props.buttonText || 'Go'}
						</Button>
					</Grid>
				</Grid>
			</Form>
		</Formik>
	);
};
