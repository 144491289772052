import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { StyledCard } from 'Common/Elements';
import { AreaSelector } from 'Common/Forms';
import { DOMHelper } from 'Common/Helpers';

export const Home = () => {
	useEffect(() => {
		DOMHelper.setPageTitle('Trends');
	}, []);

	return (
		<Grid container={true}>
			<Grid item={true} md={2} lg={4} />
			<Grid item={true} xs={12} sm={8} md={8} lg={4}>
				<StyledCard title="View Trends">
					<AreaSelector />
				</StyledCard>
			</Grid>
		</Grid>
	);
};
