import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import { Layout } from './';
import { useAppState, useAppDispatch } from 'Context/App';
import { ErrorBoundary } from '@sentry/react';
import { useLocalStorage } from 'Common/Helpers/ReactHelper';
import { GoogleAnalytics } from 'Common/Utilities';

const overallTheme = createTheme({
	palette: {
		primary: {
			main: '#005175', // NRI blue
		},
		secondary: {
			main: '#475765', // conservation easements dark blue
		},
		background: {
			default: 'rgb(240, 240, 240)',
		},
	},
	typography: {
		fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
	},
	transitions: {
		duration: {
			enteringScreen: 400,
			leavingScreen: 200,
		},
	},
	overrides: {
		MuiDrawer: {
			paper: {
				backgroundColor: '#222D32',
				color: '#8aa4af',
			},
			paperAnchorDockedLeft: {
				height: '100vh',
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: 'rgba(255,255,255,0.3)',
			},
		},
		MuiListItemIcon: {
			root: {
				color: '#8aa4af',
			},
		},
		MuiListItemText: {
			primary: {
				fontSize: '14px',
			},
		},
		MuiAppBar: {
			root: {
				boxShadow: 'none',
			},
		},
	},
});

export const darkTheme = createTheme({
	...overallTheme,
	palette: {
		type: 'dark',
		primary: {
			main: '#005175', // NRI blue
		},
		secondary: {
			main: '#475765', // conservation easements dark blue
		},
	},
});

export const App = () => {
	const state = useAppState();
	const dispatch = useAppDispatch();
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const [darkModeStorage, setDarkModeStorage] = useLocalStorage('dark-mode', prefersDarkMode.toString());

	useEffect(() => {
		if (darkModeStorage) {
			dispatch({ type: 'APP/SET_THEME', payload: 'dark' });
		}
	}, [darkModeStorage]);

	useEffect(() => {
		setDarkModeStorage(state.Theme === 'dark');
	}, [state.Theme]);

	return (
		<ErrorBoundary showDialog={true}>
			<BrowserRouter>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<ThemeProvider theme={state.Theme === 'dark' ? darkTheme : overallTheme}>
						<SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
							<Layout isLoading={false} />
							<GoogleAnalytics />
						</SnackbarProvider>
					</ThemeProvider>
				</MuiPickersUtilsProvider>
			</BrowserRouter>
		</ErrorBoundary>
	);
};
