/* tslint:disable: no-import-side-effect */
import 'core-js';
import 'regenerator-runtime/runtime';
import 'Assets/global.css';

/* tslint:enable: no-import-side-effect */

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import settings from 'settings';

import { AppProvider } from 'Context/App';
import { App } from 'Layout';
import { SnackbarProvider } from 'notistack';
import { CompareProvider } from 'Context/Compare';

Sentry.init({ dsn: settings.sentry.dsn, environment: settings.sentry.environment });

ReactDOM.render(
	<SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
		<AppProvider>
			<CompareProvider>
				<App />
			</CompareProvider>
		</AppProvider>
	</SnackbarProvider>,
	document.getElementById('app')
);
