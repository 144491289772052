import React from 'react';
import { Fade, Grid } from '@material-ui/core';
import { PageHeader } from 'Layout';
import { AreaType, DetailsBase } from 'Models/DetailsModels';
import { PageContent } from 'Layout/PageContent';
import { CardBackground } from 'Common/Elements';
import { Map, MapCard, OverviewFeatureLayer } from 'Mapping';
import {
	MarketValueKeyMetricCard,
	OwnershipAcresChartCard,
	OwnershipFarmsChartCard,
	PopulationChartCard,
	PopulationKeyMetricCard,
	WorkingLandsAcresChartCard,
	WorkingLandsKeyMetricCard,
	WorkingLandsMVChartCard,
} from 'Common/Cards';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { formatWebName } from 'Common/Helpers/TextHelper';

export interface IDetailsPageTemplateProps {
	details: DetailsBase;
	title: string;
	subtitle?: string;
	areaType?: AreaType;
}

const DetailsPageTemplate = (props: IDetailsPageTemplateProps & RouteComponentProps<any>) => {
	const handleReferenceMapFeatureClick = (name: string) => {
		props.history.push(`/trends/${props.areaType}/${formatWebName(name)}`.toLowerCase());
	};

	return (
		<>
			<PageHeader
				title={props.title}
				subtitle={props.subtitle}
				sideContent={
					<CardBackground style={{ height: 'calc(100% - 16px)' }}>
						<Map initialZoom={3}>
							<OverviewFeatureLayer
								areaType={props.areaType}
								names={props.details.names}
								onFeatureClick={handleReferenceMapFeatureClick}
								zoomTo={true}
							/>
						</Map>
					</CardBackground>
				}
			>
				<Grid container={true}>
					<Grid item={true} md={4} sm={6} xs={12}>
						<WorkingLandsKeyMetricCard data={props.details.landuse} />
					</Grid>
					<Grid item={true} md={4} sm={6} xs={12}>
						<MarketValueKeyMetricCard data={props.details.landuse} />
					</Grid>
					<Grid item={true} md={4} sm={6} xs={12}>
						<PopulationKeyMetricCard data={props.details.population} />
					</Grid>
				</Grid>
			</PageHeader>
			<PageContent>
				<Fade timeout={{ enter: 1000, exit: 200 }} in={true}>
					<Grid container={true}>
						<Grid item={true} md={4} xs={12} sm={6}>
							<WorkingLandsAcresChartCard
								data={props.details.landuse}
								key={props.details.names.join(',')}
							/>
						</Grid>

						<Grid item={true} md={5} xs={12} sm={6}>
							<WorkingLandsMVChartCard data={props.details.landuse} key={props.details.names.join(',')} />
						</Grid>
						<Grid item={true} md={3} xs={12} sm={6}>
							<PopulationChartCard data={props.details.population} key={props.details.names.join(',')} />
						</Grid>
					</Grid>
				</Fade>

				<Grid container={true}>
					<Grid item={true} md={4} xs={12} sm={6}>
						<OwnershipAcresChartCard data={props.details.ownership} key={props.details.names.join(',')} />
					</Grid>
					<Grid item={true} md={4} xs={12} sm={6}>
						<OwnershipFarmsChartCard data={props.details.ownership} key={props.details.names.join(',')} />
					</Grid>

					<Grid item={true} xs={12} md={4}>
						<div id="map-card">
							<MapCard>
								<OverviewFeatureLayer
									areaType={props.areaType}
									names={props.details.names}
									color={[0, 0, 0, 1]}
								/>
							</MapCard>
						</div>
					</Grid>
				</Grid>
			</PageContent>
		</>
	);
};

export const DetailsPageTemplateWithRouter = withRouter(DetailsPageTemplate);
