import { Step, StepConnector, StepLabel, Stepper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import React from 'react';
import StoreIcon from '@material-ui/icons/Store';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleIcon from '@material-ui/icons/GroupAdd';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import HomeIcon from '@material-ui/icons/Home';
import LoopIcon from '@material-ui/icons/Loop';
import classNames from 'classnames';

const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},

	completed: {
		backgroundImage: 'linear-gradient( 136deg, rgb(80, 117, 87) 0%, rgb(50, 74, 55) 100%)',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
});

const Connector = withStyles({
	alternativeLabel: {
		top: 22,
	},
	active: {
		'& $line': {
			backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 100%)',
		},
	},
	completed: {
		'& $line': {
			backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 100%)',
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
	},
})(StepConnector);

const steps: { title: string; icon: any }[] = [
	{ title: 'Economic Growth', icon: <StoreIcon /> },
	{ title: 'Population Growth', icon: <PeopleIcon /> },
	{ title: 'Increased Demand for Rural Land', icon: <TrendingUpIcon /> },
	{ title: 'High Land Values', icon: <MoneyIcon /> },
	{ title: 'Incentives to Subdivide or Sell', icon: <HomeIcon /> },
	{ title: 'Land Fragmentation or Conversion', icon: <LoopIcon /> },
];

export const FragmentationProcess = () => {
	const classes = useColorlibStepIconStyles();
	return (
		<Stepper alternativeLabel activeStep={steps.length - 1} connector={<Connector />} style={{ padding: 0 }}>
			{steps.map(s => (
				<Step key={s.title}>
					<StepLabel
						StepIconComponent={() => (
							<div className={classNames(classes.root, classes.completed)}>{s.icon}</div>
						)}
					>
						{s.title}
					</StepLabel>
				</Step>
			))}
		</Stepper>
	);
};
