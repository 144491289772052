export const truncate = (str: string, length = 100, ending = '...'): string => {
	if (str.length > length) {
		return str.substring(0, length - ending.length) + ending;
	}
	return str;
};

export const isEmptyOrWhitespace = (str: string): boolean => {
	return !str || str.length === 0 || /^\s*$/.test(str);
};

/** Formats the supplied number to currency with exactly two decimal places */
export const money = (x: number | undefined): string => {
	if (x === undefined || isNaN(x)) {
		x = 0;
	}
	const curr = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(x);
	// curr = (isNaN(curr)) ? '$0.00' : curr;
	return curr;
};

/** Formats the supplied number as a percentage. The number should be a decimal between 0 and 1 */
export const percentage = (x: number | undefined): string => {
	if (!x) {
		x = 0;
	}

	return (x * 100).toFixed(2) + '%';
};

export const decimal = (x: number | undefined): string => {
	let deci = '0.00';
	if (x !== undefined) {
		deci = x.toFixed(2);
	}

	return deci;
};

export const largeNumber = (x: number | undefined, shortenMillion: boolean = false): string => {
	let formatted = '';
	if (x !== undefined) {
		formatted = x > 999999 && shortenMillion ? x / 1000000 + 'M' : x.toLocaleString('en');
	}

	return formatted;
};

export const formatWebName = (name: string) => {
	const formatted = name.replace(/&/g, '-').replace(/ /g, '-');
	console.log(formatted);
	return formatted;
};
