import { useEffect } from 'react';
import { loadModules } from 'esri-loader';

export interface IBasemapGalleryProps {
	map?: __esri.Map;
	view?: __esri.MapView;
	position?: UIPosition;
}

export const BasemapGallery = (props: IBasemapGalleryProps) => {
	useEffect(() => {
		loadModules([
			'esri/widgets/BasemapGallery',
			'esri/widgets/BasemapGallery/support/PortalBasemapsSource',
			'esri/widgets/Expand',
		])
			.then(
				([Gallery, PortalSource, Expand]: [
					__esri.BasemapGalleryConstructor,
					__esri.PortalBasemapsSourceConstructor,
					__esri.ExpandConstructor
				]) => {
					const view = props.view!;

					const allowedBasemapTitles = ['Streets', 'Imagery', 'Oceans', 'Topographic', 'Streets (Night)'];
					const source = new PortalSource({
						// filtering portal basemaps
						filterFunction: (basemap: __esri.Basemap) =>
							allowedBasemapTitles.indexOf(basemap.portalItem.title) > -1,
					});

					const basemapGallery = new Gallery({
						view: view,
						source: source,
						container: document.createElement('div'),
					});

					const expand = new Expand({
						view: view,
						content: basemapGallery,
						expandTooltip: 'Change Basemap',
						mode: 'floating',
					});
					view.ui.add(expand, props.position || 'top-right');

					// Using the "watch" concept
					// https://developers.arcgis.com/javascript/latest/guide/index.html#watching-property-changes
					// to watch the "activeBasemap" property of the BasemapGallery
					// When it changes, close the Expand widget.
					basemapGallery.watch('activeBasemap', () => (expand.expanded = !expand.expanded));
				}
			)
			.catch(err => console.error(err));
	}, []);

	return null;
};
