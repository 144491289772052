import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
	content: {
		marginTop: -75,
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
}));

export const PageContent: React.FC = props => {
	const classes = useStyles();

	return <Box className={classes.content}>{props.children}</Box>;
};
