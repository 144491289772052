import React, { useState, useMemo, MutableRefObject } from 'react';
import { IStyledCardProps, StyledCard } from './StyledCard';
import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { ChartCardView } from 'Models/ChartModels';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import TableIcon from '@material-ui/icons/Toc';
import InfoIcon from '@material-ui/icons/Info';
import { DownloadIcon } from '.';
import { useCurrentPng } from 'recharts-to-png';
import FileSaver from 'file-saver';

export interface IChartCardProps extends IStyledCardProps {
	subtitle?: string;
	children: (selectedView: ChartCardView, ref: MutableRefObject<any> | null) => React.ReactNode;
	exportTitle?: string;
	views?: ChartCardView[];
}

const useStyles = makeStyles(() => ({
	button: {
		padding: 4,
	},
	subtitle: {
		marginLeft: 4,
	},
}));

export const ChartCard = (props: IChartCardProps) => {
	const classes = useStyles();
	const [selectedView, setSelectedView] = useState(ChartCardView.Chart);
	const [getPng, { ref: chartRef }] = useCurrentPng();

	const handleDownload = React.useCallback(async () => {
		const png = await getPng();

		// Verify that png is not undefined
		if (png) {
			// Download with FileSaver
			FileSaver.saveAs(png, (props.exportTitle || props.title) + '.png');
		}
	}, [getPng]);

	const cardTitle = props.subtitle ? (
		<div>
			{props.title}
			<Typography variant="overline" className={classes.subtitle}>
				{props.subtitle}
			</Typography>
		</div>
	) : (
		props.title
	);

	const memoChildren = useMemo(() => props.children(selectedView, chartRef), [selectedView]);

	return (
		<StyledCard
			title={cardTitle}
			actions={[
				{
					tooltip: 'Download Chart',
					icon: <DownloadIcon />,
					onClick: handleDownload,
				},
				...(props.actions || []),
			]}
			additionalElement={
				<ToggleButtonGroup
					value={selectedView}
					exclusive={true}
					onChange={(e, newVal: ChartCardView | null) => {
						if (newVal !== null) {
							setSelectedView(newVal);
						}
					}}
					size="small"
					aria-label="select card view"
				>
					<ToggleButton value={ChartCardView.Chart} aria-label="view as chart" className={classes.button}>
						<Tooltip title="View as chart">
							<EqualizerIcon fontSize="small" />
						</Tooltip>
					</ToggleButton>

					<ToggleButton value={ChartCardView.Table} aria-label="view as table" className={classes.button}>
						<Tooltip title="View as table">
							<TableIcon fontSize="small" />
						</Tooltip>
					</ToggleButton>

					<ToggleButton
						value={ChartCardView.Info}
						aria-label="view additional information"
						className={classes.button}
					>
						<Tooltip title="View additional information">
							<InfoIcon fontSize="small" />
						</Tooltip>
					</ToggleButton>
				</ToggleButtonGroup>
			}
		>
			{memoChildren}
		</StyledCard>
	);
};
