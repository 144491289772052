import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { DOMHelper } from 'Common/Helpers';
import { useEcoregionEndpoint } from 'Endpoints';
import { EcoregionDetails } from 'Models/EcoregionModels';
import { LinearProgress } from '@material-ui/core';
import { DetailsPageTemplate } from 'Common/PageTemplates';

export const Ecoregion = (props: RouteComponentProps<{ id: string }>) => {
	const ep = useEcoregionEndpoint();
	const [ecoregion, setEcoregion] = useState<EcoregionDetails>();
	const [title, setTitle] = useState('');

	useEffect(() => {
		ep.GetDetails(props.match.params.id).then(r => {
			if (r !== undefined) {
				const t = `${r.names.join(', ')} ${r.names.length === 1 ? 'Ecoregion' : 'Ecoregions'}`;
				setTitle(t);
				DOMHelper.setPageTitle(t);
				setEcoregion(r);
			}
		});
	}, [props.match.params.id]);

	if (!ecoregion || ep.IsLoading) {
		return <LinearProgress />;
	}

	return <DetailsPageTemplate key={title} details={ecoregion} title={title} areaType="Ecoregion" />;
};
