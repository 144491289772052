import { useNotifications } from 'Common/Notifications';
import { CAD, CADDetails } from 'Models/CADModels';
import { Endpoint } from './Endpoint';

export const useCADEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('cads');

	const Get = () =>
		ep.Get<CAD[]>().catch(() => {
			useError('Error getting counties');
			return [] as CAD[];
		});

	const GetDetails = (id: string) =>
		ep.Get<CADDetails>(id).catch(() => {
			useError('Error getting county details');
			return undefined;
		});

	return { Get, GetDetails, IsLoading: ep.IsLoading };
};
