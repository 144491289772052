import { makeStyles } from '@material-ui/styles';

export const useLayoutStyles = makeStyles(theme => ({
	flexGrow: {
		flexGrow: 1,
	},
	flex: {
		flex: '0 0 auto',
	},
}));
