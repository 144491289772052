import { Landuse, PivotedLanduse } from 'Models/DetailsModels';

/** Creates an array of objects. Each object has a group key, and its related items.
 * This does not perform aggregations
 */
export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
	list.reduce((previous, currentItem) => {
		const currentGroup = getKey(currentItem);
		// make an empty group if it's not found yet
		if (previous.findIndex(p => p.group === currentGroup) === -1) previous.push({ group: currentGroup, items: [] });
		// add items to the group. use exclamation because we know it won't be undefined
		previous.find(p => p.group === currentGroup)!.items.push(currentItem);
		return previous;
	}, [] as { group: K; items: T[] }[]);

/** groups an array by the given key, and returns a single array with the key summed */
export const group = <T, G extends keyof any, S extends keyof any>(
	list: T[],
	groupKey: (item: T) => G,
	sumKey: (item: T) => S
) =>
	list.reduce((previous, currentItem) => {
		const currentGroup = groupKey(currentItem);
		const currentValue = sumKey(currentItem);
		const existingGroup = previous.find(p => p.group === currentGroup);

		// make an new group if it's not found yet
		if (existingGroup === undefined) {
			previous.push({ group: currentGroup, value: +currentValue });
		} else {
			// add to the existing group
			existingGroup.value += +currentValue;
		}

		return previous;
	}, [] as { group: G; value: number }[]);

export const pivotLanduse = (list: Landuse[], getValue: (item: Landuse) => number) => {
	const setPivotProps = (data: PivotedLanduse, value: Landuse) => {
		switch (value.landuseGroupName) {
			case 'Grazing Land':
				data = { ...data, grazingLand: getValue(value) };
				break;
			case 'Cropland':
				data = { ...data, cropland: getValue(value) };
				break;
			case 'Wildlife Management':
				data = { ...data, wildlifeManagement: getValue(value) };
				break;
			case 'Timber':
				data = { ...data, timber: getValue(value) };
				break;
			case 'Other':
				data = { ...data, other: getValue(value) };
				break;
		}

		return data;
	};

	return list.reduce((acc, value) => {
		let existingYear = acc.find(v => v.year === value.year);
		if (existingYear === undefined) {
			let newYear = new PivotedLanduse(value.year);
			newYear = setPivotProps(newYear, value);
			acc.push(newYear);
		} else {
			// add to the existing year
			existingYear = setPivotProps(existingYear, value);
			acc = [...acc.filter(d => d.year !== existingYear!.year), existingYear];
		}

		return acc;
	}, [] as PivotedLanduse[]);
};
