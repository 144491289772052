import { useCADEndpoint, useEcoregionEndpoint, useRiverBasinEndpoint, useROIEndpoint } from 'Endpoints';
import { CAD } from 'Models/CADModels';
import { Ecoregion } from 'Models/EcoregionModels';
import { RiverBasin } from 'Models/RiverBasinModels';
import { ROI } from 'Models/ROIModels';
import React, { createContext, Dispatch, useReducer, useContext, useEffect } from 'react';
import { ActionType, createAction } from 'typesafe-actions';

// actions
const Actions = {
	SetTheme: createAction('APP/SET_THEME')<'light' | 'dark'>(),
	LookupsLoaded: createAction('APP/LOOKUPS_LOADED')<{
		cads: CAD[];
		ecoregions: Ecoregion[];
		riverBasins: RiverBasin[];
		rois: ROI[];
	}>(),
};

// state
class State {
	Theme: 'light' | 'dark' = 'light';
	cads: CAD[] = [];
	ecoregions: Ecoregion[] = [];
	riverBasins: RiverBasin[] = [];
	rois: ROI[] = [];
}

// reducer
const AppReducer = (state: State, action: ActionType<typeof Actions>): State => {
	switch (action.type) {
		case 'APP/SET_THEME':
			return { ...state, Theme: action.payload };
		case 'APP/LOOKUPS_LOADED':
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

// init contexts
// they are separate to reduce unnecessary re-renders when state changes
const initialState = new State();
const AppStateContext = createContext(initialState);
// tslint:disable-next-line: no-empty
const AppDispatchContext = createContext<Dispatch<ActionType<typeof Actions>>>(() => {});

const AppProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(AppReducer, initialState);

	const cadEp = useCADEndpoint();
	const ecoregionEp = useEcoregionEndpoint();
	const riverBasinEp = useRiverBasinEndpoint();
	const roiEp = useROIEndpoint();

	useEffect(() => {
		Promise.all([cadEp.Get(), ecoregionEp.Get(), riverBasinEp.Get(), roiEp.Get()]).then(r =>
			dispatch({
				type: 'APP/LOOKUPS_LOADED',
				payload: { cads: r[0], ecoregions: r[1], riverBasins: r[2], rois: r[3] },
			})
		);
	}, []);

	return (
		<AppStateContext.Provider value={state}>
			<AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
		</AppStateContext.Provider>
	);
};

const useAppState = () => {
	return useContext(AppStateContext);
};

const useAppDispatch = () => {
	return useContext(AppDispatchContext);
};

export { AppProvider, useAppState, useAppDispatch };
