import { useNotifications } from 'Common/Notifications';
import { RiverBasin, RiverBasinDetails } from 'Models/RiverBasinModels';
import { Endpoint } from './Endpoint';

export const useRiverBasinEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('RiverBasins');

	const Get = () =>
		ep.Get<RiverBasin[]>().catch(() => {
			useError('Error getting river basins');
			return [] as RiverBasin[];
		});

	const GetDetails = (id: string) =>
		ep.Get<RiverBasinDetails>(id).catch(() => {
			useError('Error getting river basin details');
			return undefined;
		});

	return { Get, GetDetails, IsLoading: ep.IsLoading };
};
