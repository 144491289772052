import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { DOMHelper } from 'Common/Helpers';
import { useRiverBasinEndpoint } from 'Endpoints';
import { LinearProgress } from '@material-ui/core';
import { DetailsPageTemplate } from 'Common/PageTemplates';
import { RiverBasinDetails } from 'Models/RiverBasinModels';

export const RiverBasin = (props: RouteComponentProps<{ id: string }>) => {
	const ep = useRiverBasinEndpoint();
	const [riverBasin, setRiverBasin] = useState<RiverBasinDetails>();
	const [title, setTitle] = useState('');

	useEffect(() => {
		ep.GetDetails(props.match.params.id).then(r => {
			if (r !== undefined) {
				const t = `${r.names.join(', ')} ${r.names.length === 1 ? 'River Basin' : 'River Basins'}`;
				setTitle(t);
				DOMHelper.setPageTitle(t);
				setRiverBasin(r);
			}
		});
	}, [props.match.params.id]);

	if (!riverBasin || ep.IsLoading) {
		return <LinearProgress />;
	}

	return <DetailsPageTemplate key={title} details={riverBasin} title={title} areaType="RiverBasin" />;
};
