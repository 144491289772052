import React from 'react';
import { Field } from 'formik';
import { GlobalAutocomplete } from '.';
import { Ecoregion } from 'Models/EcoregionModels';

export interface IEcoregionSelectProps {
	/** The array of partners that will be shown as dropdown items */
	options: Ecoregion[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the nameof generic (e.g. nameof<MyModel>('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	multiple?: boolean;
	/** For controlled autocomplete, if no value is provided it will be an empty Ecoregion model */
	value?: Ecoregion;
}

export const EcoregionSelect = (props: IEcoregionSelectProps) => {
	return (
		<Field
			name={props.name}
			key="CAD-auto"
			component={(p: any) => (
				<GlobalAutocomplete
					{...p}
					label={props.label || 'Ecoregions'}
					options={props.options.sort((a, b) => a.name.localeCompare(b.name))}
					multiple={props.multiple}
				/>
			)}
			getOptionLabel={(option: Ecoregion) => option.name}
			getOptionSelected={(option: Ecoregion, value: Ecoregion) => value.id === option.id}
			fullWidth={true}
			filterSelectedOptions={true}
		/>
	);
};
