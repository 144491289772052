import { Button, FormControl, Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { CAD } from 'Models/CADModels';
import React from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { CADSelect } from './Fields';

export interface ICadFormProps {
	counties: CAD[];
	onSubmit: (counties: CAD[]) => void;
	buttonText?: string;
	disabled?: boolean;
}

class FormValues {
	cads: CAD[] = [];
}

export const CADForm = (props: ICadFormProps) => {
	const nameof = useNameof<FormValues>();
	return (
		<Formik
			initialValues={new FormValues()}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				values.cads.length && props.onSubmit(values.cads);
				setSubmitting(false);
				resetForm();
			}}
		>
			<Form>
				<Grid container={true}>
					<Grid item={true} xs={12}>
						<FormControl fullWidth={true}>
							<CADSelect
								label="Select counties"
								options={props.counties}
								name={nameof('cads')}
								multiple={true}
							/>
						</FormControl>
					</Grid>
					<Grid item={true} md={8} />
					<Grid item={true} md={4}>
						<Button
							fullWidth={true}
							type="submit"
							style={{ marginLeft: 12, marginTop: 12 }}
							disabled={props.disabled}
						>
							{props.buttonText || 'Go'}
						</Button>
					</Grid>
				</Grid>
			</Form>
		</Formik>
	);
};
