import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import { useLayoutStyles } from 'Layout/LayoutStyles';
import React from 'react';
import { Header5 } from './Headers';
import { IconCircle } from './IconCircle';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { Fade } from 'react-reveal';
import { largeNumber, money, percentage } from 'Common/Helpers/TextHelper';

export interface IKeyMetricCardProps {
	title: string;
	startingvalue?: number;
	endingvalue?: number;
	icon?: React.ReactNode;
	iconColors?: string[];
	delay?: number;
	unit?: '$' | 'ac';
}

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2),
	},
	row: {
		display: 'flex',
	},
	title: {
		color: theme.palette.type === 'light' ? '#8898aa' : '#d2e3f7',
		textTransform: 'uppercase',
		fontSize: '1em',
		marginBottom: 0,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	value: {
		fontWeight: 600,
		fontSize: '1.8em',
	},
	change: {
		marginRight: theme.spacing(2),
	},
	redText: {
		color: theme.palette.error.main,
	},
	greenText: {
		color: theme.palette.success.main,
	},
}));

export const KeyMetricCard: React.FC<IKeyMetricCardProps> = props => {
	const classes = useStyles();
	const layoutClasses = useLayoutStyles();
	const delay = props.delay || 0;

	const totalChange = () => {
		if (props.startingvalue === undefined || props.endingvalue === undefined) {
			return '';
		}

		const change = props.endingvalue - props.startingvalue;
		switch (props.unit) {
			case undefined:
				return largeNumber(change);
			case '$':
				return money(change);
			case 'ac':
				return `${largeNumber(change)} ac`;
		}
	};

	const getPctChange = () => {
		if (props.startingvalue === undefined || props.endingvalue === undefined) {
			return undefined;
		}

		return (props.endingvalue - props.startingvalue) / props.startingvalue;
	};

	const pctChange = getPctChange();

	return (
		<Fade delay={delay} bottom={true}>
			<Paper className={classes.root}>
				<Grid container={true}>
					<Grid item={true} xs={10} sm={11} md={10} className={layoutClasses.flexGrow}>
						<Header5 title={props.title} className={classes.title} />
						<div className={classes.value}>{totalChange()}</div>
						{props.children}
					</Grid>
					<Grid item={true} xs={2} sm={1} lg={2} className={layoutClasses.flex}>
						{props.icon && (
							<IconCircle style={{ maxWidth: '99%' }} colors={props.iconColors} icon={props.icon} />
						)}
					</Grid>
				</Grid>

				{pctChange && (
					<div>
						<span
							className={classNames(
								classes.change,
								{ [classes.greenText]: pctChange > 0 },
								{ [classes.redText]: pctChange < 0 }
							)}
						>
							{pctChange > 0 ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
							{percentage(pctChange)}
						</span>
						since 1997
					</div>
				)}
			</Paper>
		</Fade>
	);
};
