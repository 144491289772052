import { makeStyles, Theme } from '@material-ui/core/styles';

export const useChartStyles = makeStyles<Theme, { color?: string }>(theme => ({
	coloredText: {
		color: props => props.color || theme.palette.text.primary,
		fontWeight: 500,
		marginBottom: theme.spacing(0.5),
		marginTop: 0,
	},
	tooltip: {
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ccc',
		borderColor: theme.palette.divider,
		padding: 4,
		zIndex: 10,
	},
	tooltipItem: {
		fontWeight: 600,
	},
	tooltipItemLabel: {
		color: props => props.color || theme.palette.text.primary,
		margin: `0 0 ${theme.spacing(0.5)}px`,
	},
	tooltipItemList: {
		listStyleType: 'none',
		margin: 0,
		paddingLeft: 0,
	},
	tooltipMainLabel: {
		fontWeight: 700,
		margin: `0 0 ${theme.spacing(0.5)}px`,
	},
	tooltipItemValue: {
		margin: 0,
	},
}));
