import { useNotifications } from 'Common/Notifications';
import { ROI, ROIDetails } from 'Models/ROIModels';
import { Endpoint } from './Endpoint';

export const useROIEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('rois');

	const Get = () =>
		ep.Get<ROI[]>().catch(() => {
			useError('Error getting regions of interest');
			return [] as ROI[];
		});

	const GetDetails = (id: string) =>
		ep.Get<ROIDetails>(id).catch(() => {
			useError('Error getting region of interest details');
			return undefined;
		});

	return { Get, GetDetails, IsLoading: ep.IsLoading };
};
