import React from 'react';
import { Field } from 'formik';
import { GlobalAutocomplete } from '.';
import { ROI } from 'Models/ROIModels';

export interface IroiselectProps {
	/** The array of partners that will be shown as dropdown items */
	options: ROI[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the nameof generic (e.g. nameof<MyModel>('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	multiple?: boolean;
	/** For controlled autocomplete, if no value is provided it will be an empty ROI model */
	value?: ROI;
}

export const ROISelect = (props: IroiselectProps) => {
	return (
		<Field
			name={props.name}
			key="CAD-auto"
			component={(p: any) => (
				<GlobalAutocomplete
					{...p}
					label={props.label || 'Regions of Interest'}
					options={props.options.sort((a, b) => a.name.localeCompare(b.name))}
					multiple={props.multiple}
				/>
			)}
			getOptionLabel={(option: ROI) => option.name}
			getOptionSelected={(option: ROI, value: ROI) => value.id === option.id}
			fullWidth={true}
			filterSelectedOptions={true}
		/>
	);
};
