import React from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from '@material-ui/core';
import { Population } from 'Models/DetailsModels';
import { TextHelper } from 'Common/Helpers';
import useStyles from './TableStyles';
import settings from 'settings';
import { ChangeTooltip } from 'Common/Elements';

interface IPopulationTableProps {
	data: Population[];
}

export const PopulationTable = (props: IPopulationTableProps) => {
	const classes = useStyles();
	const columns = ['Year', 'Population'];
	const data = props.data.filter(d => settings.displayYears.includes(d.year)).sort((a, b) => a.year - b.year);

	return (
		<Box className={classes.root}>
			<Table className={classes.table} size="small">
				<TableHead>
					<TableRow>
						{columns.map(c => (
							<TableCell key={c}>{c}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map(p => {
						return (
							<TableRow key={p.year}>
								<TableCell>{p.year}</TableCell>
								<TableCell>{TextHelper.largeNumber(p.value)}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell>
							<ChangeTooltip>Change</ChangeTooltip>
						</TableCell>
						<TableCell>{TextHelper.largeNumber(data.slice(-1)[0].value - data[0].value)}</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</Box>
	);
};
