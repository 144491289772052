export enum AreaTabsOptions {
	CAD = 0,
	RiverBasin = 1,
	Ecoregion = 2,
	ROI = 3,
}

export enum AreaPrefix {
	CAD = 'c',
	Ecoregion = 'e',
	RiverBasin = 'r',
	ROI = 'i',
}

export class CompareArea {
	areaPrefix: AreaPrefix;
	areas: string;

	constructor(areaPrefix: AreaPrefix, areas: string) {
		this.areaPrefix = areaPrefix;
		this.areas = areas;
	}

	toString = () => {
		return `${this.areaPrefix}_${this.areas}`;
	};
}
