import React, { useEffect } from 'react';
import { loadCss } from 'esri-loader';
import { Map as EsriMap } from '@esri/react-arcgis';
import { useAppState } from 'Context/App';

export interface IMapProps {
	onExtentChanged?: (xmax: number, xmin: number, ymax: number, ymin: number) => void;
	initialZoom?: number;
	allowNavigation?: boolean;
}

export const Map: React.FC<IMapProps> = props => {
	const state = useAppState();

	useEffect(() => {
		loadCss();
	}, []);

	const disableForDisplay = (e: __esri.ViewDragEvent | __esri.ViewDoubleClickEvent) => {
		e.stopPropagation();
	};

	const onLoad = (map: __esri.Map, view: __esri.MapView) => {
		props.allowNavigation !== true && view.on('drag', disableForDisplay);
		props.allowNavigation !== true && view.on('double-click', disableForDisplay);
	};

	return (
		<div style={{ height: '100%', minHeight: 100 }}>
			<EsriMap
				mapProperties={{ basemap: state.Theme === 'light' ? 'topo' : 'streets-night-vector' }}
				viewProperties={{
					center: [-99.170639, 31.391528],
					zoom: props.initialZoom || 5,
					constraints: {
						minZoom: 5,
						maxZoom: 8,
						geometry: {
							type: 'extent',
							xmin: -106.5,
							ymin: 25.8,
							xmax: -93.8,
							ymax: 36.4,
						} as __esri.Extent,
					},
					ui: {
						components: [''],
					},
					navigation: {
						browserTouchPanEnabled: props.allowNavigation || false,
						mouseWheelZoomEnabled: props.allowNavigation || false,
					},
				}}
				onFail={e => console.error(e)}
				onLoad={onLoad}
			>
				{props.children}
			</EsriMap>
		</div>
	);
};

export default Map;
