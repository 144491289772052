import React from 'react';
import { Card, CardContent, Tooltip, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useHeaderStyles } from './Headers';

interface IStyledCardActionProps {
	icon: React.ReactElement;
	tooltip?: string;
	onClick: () => void;
}

export interface IStyledCardProps {
	title?: string | JSX.Element;
	actions?: IStyledCardActionProps[];
	additionalElement?: JSX.Element;
	style?: React.CSSProperties;
	contentStyle?: React.CSSProperties;
}

const useStyles = makeStyles(theme => ({
	headingElementsContainer: {
		display: 'flex',
	},
	actionsContainer: {
		display: 'flex',
	},
	icon: {
		cursor: 'pointer',
		marginLeft: theme.spacing(1),
		color: theme.palette.type === 'light' ? theme.palette.grey[600] : theme.palette.grey[400],
		padding: 4,
	},
	card: {
		overflow: 'visible',
		position: 'relative',
	},
	title: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		fontSize: '1.25em',
		justifyContent: 'space-between',
		padding: theme.spacing(2),
	},
	content: {
		paddingBottom: 0,
		paddingTop: 0,
	},
	paddingTop: {
		paddingTop: 16,
	},
}));

export const StyledCard = (props: React.PropsWithChildren<IStyledCardProps>) => {
	const classes = useStyles();
	const headerClasses = useHeaderStyles();
	const cardActions = props.actions
		? props.actions.map((a, i) => {
				const actionTooltip = a.tooltip || '';
				return (
					<Tooltip key={i} title={actionTooltip} onClick={a.onClick}>
						<IconButton className={classes.icon}>{a.icon}</IconButton>
					</Tooltip>
				);
		  })
		: [];

	return (
		<Card className={classes.card} style={props.style}>
			{props.title && (
				<Typography variant="h3" className={classNames(classes.title, headerClasses.header)}>
					{props.title}
					<div
						className={classNames(classes.headingElementsContainer, {
							['action-container']: props.additionalElement,
						})}
					>
						<div className={classes.actionsContainer}>{cardActions}</div>
						{props.additionalElement}
					</div>
				</Typography>
			)}
			<CardContent
				className={classNames(classes.content, { [classes.paddingTop]: !props.title })}
				style={props.contentStyle}
			>
				{props.children}
			</CardContent>
		</Card>
	);
};
