import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { DOMHelper } from 'Common/Helpers';
import { LinearProgress } from '@material-ui/core';
import { useStatewideEndpoint } from 'Endpoints';
import { StatewideDetails } from 'Models/StatewideModels';
import { DetailsPageTemplate } from 'Common/PageTemplates';

export const Statewide = (props: RouteComponentProps<any>) => {
	const ep = useStatewideEndpoint();
	const [details, setDetails] = useState<StatewideDetails>();

	useEffect(() => {
		DOMHelper.setPageTitle('Statewide Trends');
		ep.GetDetails().then(r => r && setDetails(r));
	}, []);

	if (!details || ep.IsLoading) {
		return <LinearProgress />;
	}

	return <DetailsPageTemplate details={details} title="Statewide" />;
};
