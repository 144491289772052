import { useEffect } from 'react';
import { loadModules } from 'esri-loader';

export interface IMapLegendProps {
	map?: __esri.Map;
	view?: __esri.MapView;
	position?: UIPosition;
}

export const MapLegend = (props: IMapLegendProps) => {
	const view = props.view!;
	useEffect(() => {
		loadModules(['esri/widgets/Legend', 'esri/widgets/Expand']).then(
			([Legend, Expand]: [__esri.LegendConstructor, __esri.ExpandConstructor]) => {
				const legend = new Legend({
					id: 'legend',
					view: view,
					style: 'card',
				});

				const expand = new Expand({
					id: 'expand',
					view: view,
					content: legend,
					expandTooltip: 'Legend',
					mode: 'floating',
				});

				view.ui.add(expand, {
					position: props.position || 'top-right',
				});
			}
		);
	}, []);
	return null;
};
