import { OwnershipChangeColumnChart } from 'Common/Charts';
import { ChartCard } from 'Common/Elements';
import { OwnershipTable } from 'Common/Tables';
import { ChartCardView } from 'Models/ChartModels';
import { Ownership } from 'Models/DetailsModels';
import React from 'react';

export interface IOwnershipFarmsChartCardProps {
	data: Ownership[];
}

export const OwnershipFarmsChartCard = (props: IOwnershipFarmsChartCardProps) => {
	return props.data.length ? (
		<ChartCard title="Ownership" subtitle="Farms">
			{(selectedView, chartRef) => {
				if (selectedView === ChartCardView.Chart) {
					return <OwnershipChangeColumnChart data={props.data} yValue="farms" innerRef={chartRef} />;
				}

				if (selectedView === ChartCardView.Table) {
					return <OwnershipTable data={props.data} displayData="farms" />;
				}

				return (
					<>
						<p>
							The USDA NASS Census of Agriculture ownership data reports working lands as the number of
							farms and acres of farms by size class each census year (1997, 2002, 2007, 2012, and 2017)
							for every county in Texas. The Ag Census defines farms/ranches as any property from which
							$1,000 or more of agricultural products were produced, sold, or normally would have been
							sold, during the census year.
						</p>
						<p>
							<a href="https://txlandtrends.org/about-the-data" target="_blank">
								About the data
							</a>
						</p>
					</>
				);
			}}
		</ChartCard>
	) : null;
};
