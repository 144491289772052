import React from 'react';
import { BasemapGallery, ImageryLayer, Map, MapLegend } from 'Mapping';

export const MapPage = () => {
	return (
		<Map>
			<BasemapGallery />
			<MapLegend />
			<ImageryLayer url="https://webservices.tnris.org/arcgis/rest/services/MRLC/National_Land_Cover_Database_2016/ImageServer" />
		</Map>
	);
};
