import { Landuse, Ownership } from 'Models/DetailsModels';

export const pivotLanduse = (
	data: Landuse[]
): { year: number; values: { acres: number; mv: number; pv: number; landuseGroupName: string; year: number }[] }[] => {
	let pivoted = [];
	pivoted = data.reduce((prev: any, cur: any) => {
		const existing = prev.find((x: { year: any }) => x.year === cur.year);
		if (existing) {
			existing.values.push(cur);
		} else {
			prev.push({
				year: cur.year,
				values: [cur],
			});
		}

		return prev;
	}, []);

	return pivoted;
};

export const pivotOwnership = (
	data: Ownership[]
): { year: number; values: { acres: number; farms: number; sizeClassGroupName: string; year: number }[] }[] => {
	let pivoted = [];
	pivoted = data.reduce((prev: any, cur: any) => {
		const existing = prev.find((x: { year: any }) => x.year === cur.year);
		if (existing) {
			existing.values.push(cur);
		} else {
			prev.push({
				year: cur.year,
				values: [cur],
			});
		}

		return prev;
	}, []);

	return pivoted;
};
