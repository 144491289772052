import React from 'react';
import { Field } from 'formik';
import { GlobalAutocomplete } from '.';
import { CAD } from 'Models/CADModels';

export interface ICADSelectProps {
	/** The array of partners that will be shown as dropdown items */
	options: CAD[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the nameof generic (e.g. nameof<MyModel>('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	/** IMPORTANT!!! Mulitple must be set to false for single select autocompletes or it bombs the application */
	multiple?: boolean;
	/** For controlled autocomplete, if no value is provided it will be an empty CAD model */
	value?: CAD;
}

export const CADSelect = (props: ICADSelectProps) => {
	return (
		<Field
			name={props.name}
			key="CAD-auto"
			component={(p: any) => (
				<GlobalAutocomplete
					{...p}
					label={props.label || 'Counties'}
					options={props.options.sort((a, b) => a.name.localeCompare(b.name))}
					multiple={props.multiple}
				/>
			)}
			getOptionLabel={(option: CAD) => option.name}
			getOptionSelected={(option: CAD, value: CAD) => value.id === option.id}
			fullWidth={true}
			filterSelectedOptions={true}
		/>
	);
};
