import React, { useState } from 'react';
import { Switch, Route } from 'react-router';
import classnames from 'classnames';

import { makeStyles, ThemeProvider } from '@material-ui/styles';
import {
	CssBaseline,
	Divider,
	Drawer,
	Fade,
	Hidden,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Theme,
	Typography,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
// import MapIcon from '@material-ui/icons/Map';
import TrendIcon from '@material-ui/icons/TrendingUp';
import InfoIcon from '@material-ui/icons/Info';
import QuestionIcon from '@material-ui/icons/Help';
import CompareIcon from '@material-ui/icons/Compare';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom';
import * as Pages from 'Pages';
import * as ComparePages from 'Pages/Compare';
import * as TrendsPages from 'Pages/Trends';
import { AppBar } from 'Layout';
import Logo from 'Assets/img/land-trends-landscape-logo-2019.png';
import IconLogo from 'Assets/img/land-trends-icon-logo.png';
import UserGuide from 'Assets/TexLandTrendsUserGuide.pdf';
import { TexasIcon } from 'Common/Elements';
import { Help } from 'Common/Help';

interface ILayoutProps {
	isLoading: boolean;
}

const drawerWidth = 255;

const contentTheme = {
	props: {
		MuiFab: {
			variant: 'extended',
			color: 'primary',
		},
		MuiButton: {
			variant: 'contained',
			color: 'primary',
		},
	},
	overrides: {
		MuiPaper: {
			elevation1: {
				padding: 8,
				margin: 8,
				boxShadow: '0 1px 4px 0 rgba(0, 0, 0, .1)',
			},
			elevation2: {
				padding: 8,
				margin: 8,
				boxShadow: '0 1px 6px 0 rgba(0, 0, 0, .12)',
			},
		},
		MuiExpansionPanelSummary: {
			root: {
				'&$expanded': {
					borderBottom: '1px solid rgba(0, 0, 0, .125)',
				},
			},
		},
		MuiTypography: {
			h5: {
				marginTop: 8,
				marginBottom: 8,
			},
		},
		MuiFab: {
			root: {
				margin: 0,
				top: 'auto',
				right: 20,
				bottom: 20,
				left: 'auto',
				position: 'fixed' as 'fixed',
			},
		},
		MuiFormControl: {
			root: {
				margin: 4,
				minWidth: 175,
			},
		},
		MuiTableRow: {
			root: {
				'&:nth-child(odd)': {
					backgroundColor: 'rgba(0,0,0,0.03)',
				},
				'&:empty': {
					display: 'none',
				},
			},
			footer: {
				backgroundColor: 'rgba(0,0,0,0)!important', // so table striping is ignored
			},
			head: {
				backgroundColor: 'rgba(0,0,0,0)!important', // so table striping is ignored
			},
		},
		MuiListItemIcon: {
			root: {
				color: 'rgba(255, 255, 255, .4)',
			},
		},
		MuiTableCell: {
			head: {
				color: '#43a047',
				fontSize: 14,
				fontWeight: 300,
			},
			body: {
				whiteSpace: 'nowrap',
			},
			sizeSmall: {
				paddingTop: 8,
				paddingBottom: 8,
				paddingRight: 12,
			},
		},
		MuiMenuItem: {
			root: {
				minHeight: 32,
			},
		},
		MuiChip: {
			label: {
				userSelect: 'auto',
			},
		},
	},
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		height: '100%',
	},
	logo: {
		width: '86%',
		paddingLeft: '14%',
		paddingTop: theme.spacing(2),
		margin: '0 auto',
	},
	appBarSpacer: theme.mixins.toolbar,
	toolbarIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	toolbarIcon: {
		color: '#fff',
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
	},
	title: {
		textTransform: 'inherit',
		marginBottom: theme.spacing(2),
		marginTop: 0,
	},
	pageLinks: {
		textDecoration: 'none',
	},
	navItem: {
		margin: '4px',
		borderRadius: '3px',
		width: 'auto',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.05)',
		},
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		zIndex: 1000,
	},
	drawerPaper: {
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.grey[700],
		boxShadow:
			'0 10px 10px -12px rgba(0, 0, 0, 0.1), 0 4px 10px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.1)',
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
}));

interface INavContentProps {
	expanded: boolean;
	onItemClick: () => void;
}

const NavContent = (props: INavContentProps) => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<Typography variant="h5" component="h1" className={`${classes.title}`}>
				<a href="https://txlandtrends.org" style={{ borderBottom: 'none' }}>
					<img src={props.expanded ? Logo : IconLogo} alt="Land Trends Logo" className={classes.logo} />
				</a>
			</Typography>
			<Divider />
			<List>
				<Link to="/" className={classes.pageLinks} onClick={props.onItemClick}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<HomeIcon />
						</ListItemIcon>
						{props.expanded && <ListItemText primary="Home" />}
					</ListItem>
				</Link>
				<Link to="/trends" className={classes.pageLinks} onClick={props.onItemClick}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<TrendIcon />
						</ListItemIcon>
						{props.expanded && <ListItemText primary="Trends" />}
					</ListItem>
				</Link>
				<Link to="/trends/statewide" className={classes.pageLinks} onClick={props.onItemClick}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<TexasIcon />
						</ListItemIcon>
						{props.expanded && <ListItemText primary="Statewide Trends" style={{ marginLeft: 12 }} />}
					</ListItem>
				</Link>
				<Link to="/compare" className={classes.pageLinks} onClick={props.onItemClick}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<CompareIcon />
						</ListItemIcon>
						{props.expanded && <ListItemText primary="Compare" />}
					</ListItem>
				</Link>
				{/* <Link to="/map" className={classes.pageLinks} onClick={props.onItemClick}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<MapIcon />
						</ListItemIcon>
						<ListItemText primary="Map" />
					</ListItem>
				</Link> */}
			</List>
			<Divider />
			<List>
				<a
					href="https://txlandtrends.org/about-the-data"
					className={classes.pageLinks}
					onClick={props.onItemClick}
					target="_blank"
				>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<InfoIcon />
						</ListItemIcon>
						{props.expanded && <ListItemText primary="About the Data" />}
					</ListItem>
				</a>
				<a
					href={UserGuide}
					className={classes.pageLinks}
					onClick={props.onItemClick}
					target="_blank"
					id="user-guide-link"
				>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<QuestionIcon />
						</ListItemIcon>
						{props.expanded && <ListItemText primary="User Guide" />}
					</ListItem>
				</a>
			</List>
		</React.Fragment>
	);
};

export const Layout: React.FunctionComponent<ILayoutProps> = props => {
	const classes = useStyles();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isMenuExpanded, setIsMenuExpanded] = useState(true);
	const [runHelp, setRunHelp] = useState(false);

	const closeDrawer = () => setIsDrawerOpen(false);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				isMenuExpanded={isMenuExpanded}
				onDesktopMenuClick={() => setIsMenuExpanded(!isMenuExpanded)}
				onMobileMenuClick={() => setIsDrawerOpen(!isDrawerOpen)}
				onHelpClick={() => setRunHelp(true)}
			/>

			<Hidden mdDown={true} implementation="css">
				<Drawer
					variant="permanent"
					anchor="left"
					classes={{
						paper: classnames(
							'page-links',
							classes.drawerPaper,
							!isMenuExpanded && classes.drawerPaperClose
						),
					}}
					open={true}
				>
					<Fade in={!props.isLoading}>
						<NavContent expanded={isMenuExpanded} onItemClick={closeDrawer} />
					</Fade>
				</Drawer>
			</Hidden>
			<Hidden smUp={true} implementation="css">
				<Drawer
					variant="temporary"
					anchor="left"
					ModalProps={{ onClose: () => setIsDrawerOpen(false) }}
					classes={{
						paper: classnames('page-links', classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
					}}
					open={isDrawerOpen}
				>
					<div className={classes.toolbarIconContainer}>
						<IconButton onClick={() => setIsDrawerOpen(false)}>
							<ChevronLeftIcon className={classes.toolbarIcon} />
						</IconButton>
					</div>
					<Divider />
					<NavContent expanded={isMenuExpanded} onItemClick={closeDrawer} />
				</Drawer>
			</Hidden>
			<ThemeProvider theme={overallTheme => ({ ...overallTheme, ...contentTheme })}>
				<main className={classnames(classes.content)}>
					<div className={classes.appBarSpacer} />
					{props.isLoading ? (
						<React.Fragment>
							<LinearProgress color="secondary" />
						</React.Fragment>
					) : (
						<Switch>
							<Route path="/" exact={true} component={Pages.Home} />
							<Route path="/Trends" exact={true} component={TrendsPages.Home} />
							<Route
								path="/Trends/County/populous"
								exact={true}
								component={TrendsPages.PopulousCounties}
							/>
							<Route path="/Trends/County/:id" component={TrendsPages.County} />
							<Route path="/Trends/Ecoregion/:id" component={TrendsPages.Ecoregion} />
							<Route path="/Trends/RiverBasin/:id" component={TrendsPages.RiverBasin} />
							<Route path="/Trends/ROI/:id" component={TrendsPages.ROI} />
							<Route path="/trends/Statewide" exact={true} component={TrendsPages.Statewide} />
							<Route path="/Compare/:id?" exact={true} component={ComparePages.Compare} />
							<Route path="/map" component={Pages.Map} />
						</Switch>
					)}
				</main>
			</ThemeProvider>
			<Help run={runHelp} onTourEnd={() => setRunHelp(false)} />
		</div>
	);
};
