import { Button, FormControl, Grid } from '@material-ui/core';
import { ROISelect } from './Fields';
import { Formik, Form } from 'formik';
import { ROI } from 'Models/ROIModels';
import React from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

export interface IROIFormProps {
	rois: ROI[];
	onSubmit: (rois: ROI[]) => void;
	buttonText?: string;
	disabled?: boolean;
}

class FormValues {
	rois: ROI[] = [];
}

export const ROIForm = (props: IROIFormProps) => {
	const nameof = useNameof<FormValues>();

	return (
		<Formik
			initialValues={new FormValues()}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				values.rois.length && props.onSubmit(values.rois);
				setSubmitting(false);
				resetForm();
			}}
		>
			<Form>
				<Grid container={true}>
					<Grid item={true} xs={12}>
						<FormControl fullWidth={true}>
							<ROISelect
								label="Select regions of interest"
								options={props.rois}
								name={nameof('rois')}
								multiple={true}
							/>
						</FormControl>
					</Grid>
					<Grid item={true} md={8} />
					<Grid item={true} md={4}>
						<Button
							fullWidth={true}
							type="submit"
							style={{ marginLeft: 12, marginTop: 12 }}
							disabled={props.disabled}
						>
							{props.buttonText || 'Go'}
						</Button>
					</Grid>
				</Grid>
			</Form>
		</Formik>
	);
};
