import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export interface IHistoryTrackerProps {
	onChange: (pathWithQuery: string) => void;
}

/** Tracks router history and sends an event when history changes */
export const HistoryTracker = (props: IHistoryTrackerProps) => {
	const history = useHistory();

	// https://stackoverflow.com/a/57356519
	useEffect(
		() =>
			history.listen(() => {
				props.onChange(window.location.pathname + window.location.search);
			}),
		[]
	);

	return null;
};
