export const landuseOrder = ['Cropland', 'Grazing Land', 'Other', 'Timber', 'Wildlife Management'];
export const ownershipOrder = ['1-99', '100-499', '500-999', '1000-1999', '2000+'];

export type LanduseValue = '' | 'Cropland' | 'Grazing Land' | 'Other' | 'Timber' | 'Wildlife Management';
export type AreaType = 'Ecoregion' | 'RiverBasin' | 'County' | 'ISD';

export class Landuse {
	landuseGroupName: LanduseValue = '';
	year: number = 0;
	acres: number = 0;
	pv: number = 0;
	mv: number = 0;
}

export class PivotedLanduse {
	year: number;
	cropland: number = 0;
	grazingLand: number = 0;
	timber: number = 0;
	wildlifeManagement: number = 0;
	other: number = 0;

	constructor(year: number) {
		this.year = year;
	}
}

export class Ownership {
	year: number = 0;
	sizeClassGroupName: string = '';
	farms: number = 0;
	acres: number = 0;
}

export class Population {
	year: number = 0;
	value: number = 0;
}

export abstract class DetailsBase {
	names: string[] = [];
	landuse: Landuse[] = [];
	ownership: Ownership[] = [];
	population: Population[] = [];
}
