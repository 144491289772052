import { FeatureLayer } from 'Mapping';
import { AreaType } from 'Models/DetailsModels';
import React from 'react';

export interface IOverviewMapProps {
	areaType?: AreaType;
	names: string[];
	/** rgba array */
	color?: number[];
	map?: __esri.Map;
	view?: __esri.MapView;
	onFeatureClick?: (name: string) => void;
	zoomTo?: boolean;
}

export const OverviewFeatureLayer = (props: IOverviewMapProps) => {
	switch (props.areaType) {
		case undefined:
			return (
				<FeatureLayer
					url="https://gis.nri.tamu.edu/arcgis/rest/services/TxMap/Land/MapServer/0"
					applyRenderer={true}
					map={props.map}
					view={props.view}
					legendEnabled={false}
					title="Counties"
				/>
			);
		case 'Ecoregion':
			return (
				<FeatureLayer
					url="https://gis.nri.tamu.edu/arcgis/rest/services/TxMap/Land/MapServer/2"
					nameAttribute="EcoregionName"
					filterNames={props.names}
					applyRenderer={true}
					map={props.map}
					view={props.view}
					zoomTo={props.zoomTo}
					selectedFeatureColor={props.color}
					onFeatureClick={props.onFeatureClick}
					order={1}
					graphicOrder={2}
					legendEnabled={false}
					title="Ecoregions"
				/>
			);
		case 'RiverBasin':
			return (
				<FeatureLayer
					url="https://gis.nri.tamu.edu/arcgis/rest/services/TxMap/Water/MapServer/6"
					nameAttribute="BasinName"
					filterNames={props.names}
					applyRenderer={true}
					map={props.map}
					view={props.view}
					zoomTo={props.zoomTo}
					selectedFeatureColor={props.color}
					onFeatureClick={props.onFeatureClick}
					order={1}
					graphicOrder={2}
					legendEnabled={false}
					title="River Basins"
				/>
			);
		default:
			return (
				<FeatureLayer
					url="https://gis.nri.tamu.edu/arcgis/rest/services/TxMap/Land/MapServer/0"
					nameAttribute="COUNTYNAME"
					filterNames={props.names}
					applyRenderer={true}
					map={props.map}
					view={props.view}
					zoomTo={props.zoomTo}
					selectedFeatureColor={props.color}
					onFeatureClick={props.onFeatureClick}
					order={1}
					graphicOrder={2}
					legendEnabled={false}
					title="Counties"
				/>
			);
	}
};
