import React from 'react';
import { Tooltip } from 'recharts';
import { IChartTooltipContent } from 'Models/ChartModels';
import { useChartStyles } from './ChartStyles';
import { largeNumber, money } from 'Common/Helpers/TextHelper';

interface IBarAndAreaChartTooltipContent extends IChartTooltipContent {
	year: string;
}

const BarAndAreaChartTooltipContent = (props: IBarAndAreaChartTooltipContent) => {
	if (props.active) {
		const classes = useChartStyles({});
		return (
			<div className={classes.tooltip}>
				<p className={classes.tooltipMainLabel}>{props.year}</p>
				{props.payload && (
					<ul className={classes.tooltipItemList}>
						{props.payload.map(item => {
							const itemClasses = useChartStyles({ color: item.color });
							return (
								<li key={item.dataKey as string} className={itemClasses.tooltipItem}>
									<span className={itemClasses.tooltipItemLabel}>{item.name}</span> :{' '}
									{largeNumber(item.value as number)}
								</li>
							);
						})}
					</ul>
				)}
			</div>
		);
	}
	return null;
};

export const barAndAreaChartTooltip = () => [
	<Tooltip
		allowEscapeViewBox={{ x: false, y: false }}
		content={p => <BarAndAreaChartTooltipContent year={p.label} active={p.active ?? true} payload={p.payload} />}
		wrapperStyle={{ zIndex: 10 }}
	/>,
];

interface ILineChartTooltipContent extends IChartTooltipContent {
	seriesKey?: string;
}

const LineChartTooltipContent = (props: ILineChartTooltipContent) => {
	if (props.active && props.seriesKey !== undefined && props.payload) {
		const currentItem = props.payload.find(
			item => item.dataKey === props.seriesKey || item.name === props.seriesKey
		);
		if (!currentItem) {
			return null;
		}
		const classes = useChartStyles({ color: currentItem.color });
		return (
			<div className={classes.tooltip}>
				<div className={classes.tooltipItem}>
					<p className={classes.tooltipItemLabel}>{`${currentItem.name} - ${currentItem.payload.year} `}</p>
					<p className={classes.tooltipItemValue}>{`${money(currentItem.value as number)}`}</p>
				</div>
			</div>
		);
	}
	return null;
};

export const lineChartTooltip = (props: { seriesKey?: string }) => [
	<Tooltip
		allowEscapeViewBox={{ x: false, y: false }}
		content={p => (
			<LineChartTooltipContent seriesKey={props.seriesKey} active={p.active ?? true} payload={p.payload} />
		)}
		wrapperStyle={{ zIndex: 10 }}
	/>,
];
