import { PopulationAreaChart } from 'Common/Charts';
import { ChartCard } from 'Common/Elements';
import { PopulationTable } from 'Common/Tables';
import { ChartCardView } from 'Models/ChartModels';
import { Population } from 'Models/DetailsModels';
import React from 'react';

export interface IPopulationChartCardProps {
	data: Population[];
}

export const PopulationChartCard = (props: IPopulationChartCardProps) => {
	return props.data.length ? (
		<ChartCard title="Population">
			{(selectedView, chartRef) => {
				if (selectedView === ChartCardView.Chart) {
					return <PopulationAreaChart data={props.data} innerRef={chartRef} />;
				}

				if (selectedView === ChartCardView.Table) {
					return <PopulationTable data={props.data} />;
				}

				return (
					<>
						<p>
							The Texas Department of State Health Services (DSHS) population data reports population for
							every county in Texas. Population values are from the census for census years (every 10
							years) and estimated for years between census years.
						</p>
						<p>
							<a href="https://txlandtrends.org/about-the-data" target="_blank">
								About the data
							</a>
						</p>
					</>
				);
			}}
		</ChartCard>
	) : null;
};
