import React from 'react';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import settings from 'settings';
import { KeyMetricCard } from 'Common/Elements';
import { Landuse } from 'Models/DetailsModels';

export interface IMarketValueKeyMetricCardProps {
	data: Landuse[];
}

export const MarketValueKeyMetricCard = (props: IMarketValueKeyMetricCardProps) => {
	const startingYear = settings.displayYears[0];
	const [endingYear] = settings.displayYears.slice(-1);

	const getWorkingLandsAcres = (year: number) => {
		return props.data.filter(l => l.year === year).reduce((acc, l) => acc + l.acres, 0);
	};

	const getMV = (year: number) => {
		const totalMV = props.data.filter(l => l.year === year).reduce((acc, l) => acc + l.mv, 0);
		const totalAcres = getWorkingLandsAcres(year);

		return totalMV / totalAcres;
	};

	return (
		<KeyMetricCard
			title="Market Value Change"
			startingvalue={getMV(startingYear)}
			endingvalue={getMV(endingYear)}
			unit="$"
			icon={<MoneyIcon />}
			delay={100}
		/>
	);
};
