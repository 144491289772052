import { KeyMetricCard } from 'Common/Elements';
import { Landuse } from 'Models/DetailsModels';
import React from 'react';
import { FaPagelines } from 'react-icons/fa';
import settings from 'settings';

export interface IWorkingLandsKeyMetricCardProps {
	data: Landuse[];
}

export const WorkingLandsKeyMetricCard = (props: IWorkingLandsKeyMetricCardProps) => {
	const startingYear = settings.displayYears[0];
	const [endingYear] = settings.displayYears.slice(-1);

	const getWorkingLandsAcres = (year: number) => {
		return props.data.filter(l => l.year === year).reduce((acc, l) => acc + l.acres, 0);
	};

	return (
		<KeyMetricCard
			title="Working Lands Change"
			startingvalue={getWorkingLandsAcres(startingYear)}
			endingvalue={getWorkingLandsAcres(endingYear)}
			unit="ac"
			icon={<FaPagelines size={24} />}
		/>
	);
};
