import React from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from '@material-ui/core';
import { Ownership, ownershipOrder } from 'Models/DetailsModels';
import { TextHelper, TableHelper } from 'Common/Helpers';
import useStyles from './TableStyles';
import settings from 'settings';
import { ChangeTooltip } from 'Common/Elements';

interface IOwnershipTableProps {
	data: Ownership[];
	displayData: 'acres' | 'farms';
}

export const OwnershipTable = (props: IOwnershipTableProps) => {
	const classes = useStyles();
	const pivoted = TableHelper.pivotOwnership(props.data.filter(d => settings.displayYears.includes(d.year))).sort(
		(a, b) => a.year - b.year
	);

	const calcChange = (sizeGroup: string) => {
		const start = pivoted
			.find(d => d.year === settings.displayYears[0])
			?.values.find(v => v.sizeClassGroupName === sizeGroup);

		const end = pivoted
			.find(d => d.year === settings.displayYears.slice(-1)[0])
			?.values.find(v => v.sizeClassGroupName === sizeGroup);

		if (props.displayData === 'acres') {
			return TextHelper.largeNumber((end?.acres ?? 0) - (start?.acres ?? 0));
		} else {
			return TextHelper.largeNumber((end?.farms ?? 0) - (start?.farms ?? 0));
		}
	};

	const calcOverallChange = () => {
		const start = props.data.filter(d => d.year === settings.displayYears[0]);
		const end = props.data.filter(d => d.year === settings.displayYears.slice(-1)[0]);

		if (props.displayData === 'acres') {
			return TextHelper.largeNumber(
				end.reduce((a, v) => (a += v.acres), 0) - start.reduce((a, v) => (a += v.acres), 0)
			);
		} else {
			return TextHelper.largeNumber(
				end.reduce((a, v) => (a += v.farms), 0) - start.reduce((a, v) => (a += v.farms), 0)
			);
		}
	};

	return (
		<Box className={classes.root}>
			<Table className={classes.table} size="small">
				<TableHead>
					<TableRow>
						<TableCell className={classes.noWrap}>Year</TableCell>
						<TableCell className={classes.noWrap}>1-99 ac.</TableCell>
						<TableCell className={classes.noWrap}>100-499 ac.</TableCell>
						<TableCell className={classes.noWrap}>500-999 ac.</TableCell>
						<TableCell className={classes.noWrap}>1000-1999 ac.</TableCell>
						<TableCell className={classes.noWrap}>2000+ ac.</TableCell>
						<TableCell className={classes.noWrap}>Total</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{pivoted.map(val => {
						return (
							<TableRow key={val.year}>
								<TableCell>{val.year}</TableCell>
								{ownershipOrder.map(o => {
									// map the ownershipOrder array since not all areas have values for all size classes (e.g. Loving County)
									return props.displayData === 'acres' ? (
										<TableCell key={o}>
											{TextHelper.largeNumber(
												val.values.find(v => v.sizeClassGroupName === o)?.acres ?? 0
											)}
										</TableCell>
									) : (
										<TableCell key={o} align="left">
											{TextHelper.largeNumber(
												val.values.find(v => v.sizeClassGroupName === o)?.farms ?? 0
											)}
										</TableCell>
									);
								})}
								<TableCell variant="footer">
									{TextHelper.largeNumber(
										val.values.reduce(
											(acc, v) => (acc += props.displayData === 'acres' ? v.acres : v.farms),
											0
										)
									)}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell>
							<ChangeTooltip>Change</ChangeTooltip>
						</TableCell>
						<TableCell>{calcChange(ownershipOrder[0])}</TableCell>
						<TableCell>{calcChange(ownershipOrder[1])}</TableCell>
						<TableCell>{calcChange(ownershipOrder[2])}</TableCell>
						<TableCell>{calcChange(ownershipOrder[3])}</TableCell>
						<TableCell>{calcChange(ownershipOrder[4])}</TableCell>
						<TableCell>{calcOverallChange()}</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</Box>
	);
};
