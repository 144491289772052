import React, { useEffect, useState } from 'react';
import { Grid, Hidden, LinearProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FragmentationProcess, StyledCard } from 'Common/Elements';
import { useStatewideEndpoint } from 'Endpoints';
import { StatewideDetails } from 'Models/StatewideModels';
import {
	OwnershipChangeColumnChart,
	PopulationBarChart,
	MarketValueLineChart,
	WorkingLandsStackedBarChart,
} from 'Common/Charts';
import { Fade } from 'react-reveal';
import { AreaSelector } from 'Common/Forms';
import { DOMHelper } from 'Common/Helpers';
import { Alert } from '@material-ui/lab';

export const Home = () => {
	const ep = useStatewideEndpoint();
	const [statewideDetails, setStatewideDetails] = useState<StatewideDetails>();

	useEffect(() => {
		DOMHelper.setPageTitle('Home');
		ep.GetDetails().then(r => setStatewideDetails(r));
	}, []);

	if (!statewideDetails || ep.IsLoading) {
		return <LinearProgress />;
	}

	return (
		<div>
			<Grid container={true}>
				<Grid item={true} xs={12} md={5}>
					<StyledCard>
						Texas working lands, or privately owned farms, ranches, and forests, are under increasing land
						conversion pressure driven by rapid population growth, suburbanization, and rural development,
						creating implications for rural economies. Here, we summarize land and demographic data related
						to Texas’ changing working lands in four primary sections: population growth, land value,
						ownership size, and working lands. Findings from the 2019 report are compared to previous Texas
						Land Trends reports to assess current and historic land use trends.
					</StyledCard>
				</Grid>
				<Grid item={true} xs={12} md={7}>
					<StyledCard title="The Fragmentation Process" contentStyle={{ overflow: 'auto' }}>
						<FragmentationProcess />
					</StyledCard>
				</Grid>
				<Grid container={true}>
					<Grid item={true} xs={12} sm={12} md={6} lg={6}>
						<Fade bottom={true} distance="30px">
							<StyledCard title="Population Growth">
								<Grid container={true}>
									<Grid item={true} xs={12}>
										<Hidden mdDown={true}>
											<div style={{ float: 'right', width: '50%' }}>
												<PopulationBarChart data={statewideDetails.population} />
											</div>
										</Hidden>
										According to a 2017 U.S. Census Bureau study, Texas leads the nation in
										population growth with 7 of the 15 fastest growing cities in the U.S.
										<ul>
											<li>
												Over the last 20-year period (from 1997 to 2017), Texas grew from 19M to
												29M residents, an increase of 48% or about 470,000 new residents
												annually.
											</li>
											<li>
												From the Texas Land Trends data, the majority of the population increase
												(86% or 8M new residents) occurred within the state’s{' '}
												<Link to="/trends/county/populous">
													top 25 highest total population growth counties
												</Link>
												.
											</li>
											<li>
												These counties account for only 10% of the total land area of the state,
												yet 74% of all Texans reside within these 25 counties.
											</li>
										</ul>
									</Grid>
									<Grid item={true} xs={12}>
										<Hidden lgUp={true}>
											<PopulationBarChart data={statewideDetails.population} />
										</Hidden>
									</Grid>
								</Grid>
							</StyledCard>
						</Fade>
					</Grid>
					<Grid item={true} xs={12} sm={12} md={6} lg={6}>
						<Fade bottom={true} distance="50px" delay={50}>
							<StyledCard title="Land Value">
								<Grid container={true}>
									<Grid item={true} xs={12}>
										<Hidden mdDown={true}>
											<div style={{ float: 'right', width: '62%' }}>
												<MarketValueLineChart data={statewideDetails.landuse} />
											</div>
										</Hidden>
										Historically, land market values served as a strong indicator of rural land
										demand. Like more traditional home real estate values, rural land market values
										vary by location, land use, property size, and other factors.
										<ul>
											<li>
												On average, working lands experienced a 24% increase in land value since
												2012 ($1,569/acre), and a 291% increase over the 20-year period
												($499/acre in 1997).
											</li>
											<li>
												The <Link to="/trends/roi/i35">I35 Corridor</Link> experienced the
												greatest absolute increase in land market value of any urban area at
												$7,396/acre.
											</li>
										</ul>
									</Grid>
									<Grid item={true} xs={12}>
										<Hidden lgUp={true}>
											<MarketValueLineChart data={statewideDetails.landuse} />
										</Hidden>
									</Grid>
								</Grid>
							</StyledCard>
						</Fade>
					</Grid>
					<Grid item={true} xs={12} sm={12} md={6} lg={6}>
						<Fade bottom={true} distance="50px" delay={100}>
							<StyledCard title="Ownership Size">
								<Grid container={true}>
									<Grid item={true} xs={12}>
										<Hidden lgUp={true}>
											<OwnershipChangeColumnChart
												data={statewideDetails.ownership}
												yValue="farms"
											/>
										</Hidden>
									</Grid>
									<Grid item={true} xs={12}>
										<Hidden mdDown={true}>
											<div style={{ float: 'left', width: '50%' }}>
												<OwnershipChangeColumnChart
													data={statewideDetails.ownership}
													yValue="farms"
												/>
											</div>
										</Hidden>
										For both small and mid-sized ownership classes, ownership fragmentation
										continues to be closely related to proximity to major urban areas and/or
										transportation corridors. As urban centers grow in human population, the demand
										for surrounding rural land increases, raising land market values and
										subsequently increasing landowners’ incentive to subdivide or sell their working
										lands for suburban/urban use. In contrast, consolidation of working lands may be
										driven by the ability of prospective buyers to capitalize on both land values
										and land availability, appearing to occur in certain regions of the state (e.g.,{' '}
										<Link to="/trends/ecoregion/high-plains">High Plains</Link> and{' '}
										<Link to="/trends/ecoregion/rolling-plains">Rolling Plains</Link> Ecological
										Regions).
										<ul>
											<li>
												Texas gained approximately 1,000 new working farms and ranches per year
												from 1997 to 2017, totaling greater than 248,000 ownerships in all.
											</li>

											<li>
												Small farms and ranches (tracts &gt;100 acres in size) represent 58% of
												all ownerships, but only account for 4% of the land mass for all working
												lands.
											</li>
										</ul>
									</Grid>
								</Grid>
							</StyledCard>
						</Fade>
					</Grid>
					<Grid item={true} xs={12} sm={12} md={6} lg={6}>
						<Fade bottom={true} distance="50px" delay={150}>
							<StyledCard title="Working Lands">
								<Grid container={true}>
									<Grid item={true} xs={12}>
										<Hidden lgUp={true}>
											<WorkingLandsStackedBarChart data={statewideDetails.landuse} />
										</Hidden>
									</Grid>
									<Grid item={true} xs={12}>
										<Hidden mdDown={true}>
											<div style={{ float: 'left', width: '60%' }}>
												<WorkingLandsStackedBarChart data={statewideDetails.landuse} />
											</div>
										</Hidden>
										From 1997 to 2017, Texas lost approximately 2.2M acres of designated working
										lands, converted to non-agricultural uses, with a substantial conversion of
										nearly 1.2M acres in the last 5-year period.
										<ul>
											<li>
												The annual land conversion rate within the{' '}
												<Link to="/trends/county/populous">top 25 highest growth counties</Link>{' '}
												over the 20-year period was about 60K acres per year (56% of total
												loss), compared to the remaining 229 county annual average of more than
												46K acres per year (43% of total loss).
											</li>
											<li>
												Grazing lands have steadily decreased since 1997, losing and/or
												converting roughly 4.6M acres to other land uses for the 20-year period.
											</li>
											<li>
												Wildlife management acres have significantly increased in recent years,
												now totaling approximately 5.4M acres from about 94,000 in 1997.
											</li>
										</ul>
									</Grid>
								</Grid>
							</StyledCard>
						</Fade>
					</Grid>
				</Grid>

				<Grid container={true}>
					<Grid item={true} xs={12} md={3} />
					<Grid item={true} xs={12} md={6}>
						<StyledCard title="Select a custom area below to explore trends">
							<Grid container={true}>
								<Grid item={true} xs={12} md={8}>
									<AreaSelector />
								</Grid>
								<Grid item={true} xs={12} md={4}>
									<Alert severity="info" style={{ marginLeft: 16 }}>
										Select an area type in the tabs to the left, then select one or more areas from
										the dropdown.
									</Alert>
								</Grid>
							</Grid>
						</StyledCard>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
