import { Tooltip } from '@material-ui/core';
import React from 'react';
import settings from 'settings';

export const ChangeTooltip: React.FC = props => {
	const startingYear = settings.displayYears[0];
	const [endingYear] = settings.displayYears.slice(-1);

	return (
		<Tooltip title={`${startingYear}-${endingYear} Change`}>
			<span style={{ borderBottom: 'dashed 1px #ccc' }}>{props.children}</span>
		</Tooltip>
	);
};
