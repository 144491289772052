import { greens, ordinal } from 'Common/Colors';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { Population } from 'Models/DetailsModels';
import React, { MutableRefObject } from 'react';
import { Legend, Area, AreaChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts';
import settings from 'settings';
import { TooltipComponents } from './Common';

export interface IPopulationAreaChartProps {
	data: Population[];
	innerRef?: MutableRefObject<any> | null;
}

export const PopulationAreaChart = React.memo((props: IPopulationAreaChartProps) => {
	const nameof = useNameof<Population>();
	const data = props.data.filter(p => settings.displayYears.includes(p.year)).sort((a, b) => a.year - b.year);

	return (
		<ResponsiveContainer width="99%" minHeight="250px">
			<AreaChart
				ref={props.innerRef}
				width={500}
				height={400}
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="year" />
				<YAxis
					tickFormatter={tick => {
						return tick.toLocaleString();
					}}
				/>
				{TooltipComponents.barAndAreaChartTooltip()}
				<Legend />
				<Area
					type="monotone"
					dataKey={nameof('value')}
					stroke={greens[0]}
					fill={ordinal[0]}
					name="Population"
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
});
