// export const ordinal = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#D900FF', '#FF0000'];
export const ordinal = ['#3E5467', '#496C4F', '#D48E4C', '#7FA3B8', '#D3AF6D', '#FF0000'];
export const greens = ['#496C4F'];
export const grayGreen5 = ['#C2C2C2', '#A7B3A6', '#839481', '#63755F', '#465740'];
export const grayGreen6 = ['#C2C2C2', '#A7B3A6', '#839481', '#63755F', '#566650', '#465740'];
export const grayGreen8 = ['#C2C2C2', '#A7B3A6', '#92A690', '#839481', '#758772', '#63755F', '#566650', '#465740'];
export const grayGreen10 = [
	'#D6D6D6',
	'#C2C2C2',
	'#B1B2AD',
	'#A7B3A6',
	'#92A690',
	'#839481',
	'#758772',
	'#63755F',
	'#566650',
	'#465740',
];
export const redGrayGreen7 = ['#665050', '#877272', '#A69090', '#D6D6D6', '#92A690', '#758772', '#566650'];
