import React from 'react';
import { XAxis } from 'recharts';

interface IEnhancedXAxis {
	dataKey?: string;
}

export const enhancedXAxis = (props: IEnhancedXAxis) => [
	<XAxis dataKey={props.dataKey} axisLine={{ strokeWidth: 2 }} />,
];
