import { useEffect } from 'react';
import { loadModules } from 'esri-loader';

export interface IPrintProps {
	map?: __esri.Map;
	view?: __esri.MapView;
	position?: UIPosition;
	defaultTitle?: string;
}

export const Print = (props: IPrintProps) => {
	useEffect(() => {
		loadModules(['esri/widgets/Print', 'esri/widgets/Expand'])
			.then(([Print, Expand]: [__esri.PrintConstructor, __esri.ExpandConstructor]) => {
				const view = props.view!;

				const print = new Print({
					view: view,
					// specify your own print service
					printServiceUrl:
						'https://gis.nri.tamu.edu/arcgis/rest/services/LandTrends/LTPrintLayout_New/GPServer/Export%20Web%20Map',
					allowedFormats: ['jpg', 'png32', 'pdf'],
					allowedLayouts: ['letter-ansi-a-landscape', 'letter-ansi-a-portrait'],
					templateOptions: {
						title: props.defaultTitle || 'Land Trends',
						copyright: 'Texas A&M NRI',
					},
				});

				const expand = new Expand({
					view: view,
					content: print,
					expandTooltip: 'Print/Export Map',
					mode: 'floating',
					id: 'print-expand',
				});

				view.ui.remove('print-expand');
				view.ui.add(expand, {
					position: props.position || 'top-right',
				});
				// did my typical setTimeout since I couldn't get any events to fire for expand
				// and simply having inline with other code made the element return undefined
				setTimeout(function () {
					const mapOnlyElemId = document.querySelector('[data-tab-id="mapOnlyTab"]')?.id;
					console.log('map only', mapOnlyElemId);
					if (mapOnlyElemId) {
						document.getElementById(mapOnlyElemId)?.remove();
					}
				}, 1000);
			})
			.catch(err => console.error(err));
	}, [props.defaultTitle]);

	return null;
};
