import { AppBar, Grid, Tab, Tabs } from '@material-ui/core';
import { StyledCard, TabPanel } from 'Common/Elements';
import { CADForm, RiverBasinForm, EcoregionForm, ROIForm } from 'Common/Forms';
import { useAppState } from 'Context/App';
import { useCompareDispatch, useCompareState } from 'Context/Compare';
import { CompareArea, AreaPrefix, AreaTabsOptions } from 'Models/AppModels';
import React, { useState } from 'react';

export interface ICompareSelectorProps {
	style?: React.CSSProperties;
}

export const CompareSelector = (props: ICompareSelectorProps) => {
	const { limitReached } = useCompareState();
	const { cads, ecoregions, riverBasins, rois } = useAppState();
	const dispatch = useCompareDispatch();
	const [selectedTab, setSelectedTab] = useState<AreaTabsOptions>(AreaTabsOptions.CAD);

	return (
		<StyledCard title="Select Comparison Areas" style={props.style}>
			<Grid container={true}>
				<Grid item={true} xs={12} style={{ flexGrow: 1, flexBasis: 60, flexShrink: 1 }}>
					<AppBar position="static" color="default" elevation={0} style={{ height: '100%' }}>
						<Tabs
							orientation="vertical"
							variant="scrollable"
							value={selectedTab}
							onChange={(event: React.ChangeEvent<{}>, newValue: number) => setSelectedTab(newValue)}
							aria-label="Area Tabs"
						>
							<Tab label="County" />
							<Tab label="River Basin" />
							<Tab label="Ecoregion" />
							<Tab label="ROI" />
						</Tabs>
					</AppBar>
				</Grid>
				<Grid item={true} xs={12} style={{ flexGrow: 1, flexBasis: 225, flexShrink: 1 }}>
					<TabPanel value={selectedTab} index={AreaTabsOptions.CAD}>
						<CADForm
							counties={cads}
							disabled={limitReached}
							onSubmit={c =>
								dispatch({
									type: 'COMPARE/ADD_AREA',
									payload: new CompareArea(AreaPrefix.CAD, c.map(x => x.id).join(',')),
								})
							}
						/>
					</TabPanel>
					<TabPanel value={selectedTab} index={AreaTabsOptions.RiverBasin}>
						<RiverBasinForm
							riverbasins={riverBasins}
							disabled={limitReached}
							onSubmit={c =>
								dispatch({
									type: 'COMPARE/ADD_AREA',
									payload: new CompareArea(AreaPrefix.RiverBasin, c.map(x => x.id).join(',')),
								})
							}
						/>
					</TabPanel>
					<TabPanel value={selectedTab} index={AreaTabsOptions.Ecoregion}>
						<EcoregionForm
							ecoregions={ecoregions}
							disabled={limitReached}
							onSubmit={c =>
								dispatch({
									type: 'COMPARE/ADD_AREA',
									payload: new CompareArea(AreaPrefix.Ecoregion, c.map(x => x.id).join(',')),
								})
							}
						/>
					</TabPanel>
					<TabPanel value={selectedTab} index={AreaTabsOptions.ROI}>
						<ROIForm
							rois={rois}
							disabled={limitReached}
							onSubmit={c =>
								dispatch({
									type: 'COMPARE/ADD_AREA',
									payload: new CompareArea(AreaPrefix.ROI, c.map(x => x.id).join(',')),
								})
							}
						/>
					</TabPanel>
				</Grid>
			</Grid>
		</StyledCard>
	);
};
