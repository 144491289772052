import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Header1, Header2 } from 'Common/Elements/Headers';
import React from 'react';

export interface IPageHeaderProps {
	title: string;
	subtitle?: string;
	sideContent?: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
	root: {
		paddingBottom: 75,
		backgroundColor: theme.palette.primary.main,
		padding: theme.spacing(3),
	},
	title: {
		color: 'white',
		fontSize: '2.5em',
	},
	subtitle: {
		color: '#ccc',
		fontSize: '1.25em',
	},
}));

export const PageHeader: React.FC<IPageHeaderProps> = props => {
	const classes = useStyles();

	const header = () => {
		return (
			<>
				<Header1 title={props.title} className={classes.title} />
				<Header2 title={props.subtitle || ''} className={classes.subtitle} />
			</>
		);
	};

	if (props.sideContent) {
		return (
			<Box className={classes.root}>
				<Grid container={true}>
					<Grid item={true} xs={12} md={9}>
						{header()}
						{props.children}
					</Grid>
					<Grid item={true} xs={12} md={3}>
						{props.sideContent}
					</Grid>
				</Grid>
			</Box>
		);
	}

	return (
		<Box className={classes.root}>
			{header()}
			{props.children}
		</Box>
	);
};
