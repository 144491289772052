import React, { MutableRefObject, useState } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { pivotLanduse } from 'Common/Helpers/ArrayHelper';
import { Landuse, PivotedLanduse } from 'Models/DetailsModels';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import settings from 'settings';
import { largeNumber } from 'Common/Helpers/TextHelper';
import { ordinal } from 'Common/Colors';
import { TooltipComponents } from './Common';

export interface IWorkingLandsStackedBarChartProps {
	data: Landuse[];
	innerRef?: MutableRefObject<any> | null;
}

export const WorkingLandsStackedBarChart = React.memo((props: IWorkingLandsStackedBarChartProps) => {
	const nameof = useNameof<PivotedLanduse>();
	const pivoted = pivotLanduse(
		props.data.filter(d => settings.displayYears.includes(d.year)),
		d => d.acres
	);
	const [disabledSeries, setDisabledSeries] = useState<string[]>([]);

	const toggleSeries = (dataKey: string) => {
		setDisabledSeries(series =>
			disabledSeries.includes(dataKey) ? series.filter(s => s !== dataKey) : [...series, dataKey]
		);
	};

	return (
		<div id="working-lands-chart">
			<ResponsiveContainer width="99%" minHeight="250px">
				<BarChart
					ref={props.innerRef}
					width={500}
					height={300}
					data={pivoted}
					margin={{
						top: 20,
						right: 30,
						left: 25,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey={nameof('year')} />
					<YAxis
						tickFormatter={(tick: number) => {
							return largeNumber(tick, true);
						}}
					/>
					{TooltipComponents.barAndAreaChartTooltip()}
					<Legend onClick={(e: any) => toggleSeries(e.dataKey)} wrapperStyle={{ cursor: 'pointer' }} />

					<Bar
						dataKey={nameof('cropland')}
						stackId="a"
						fill={ordinal[0]}
						name="Cropland"
						hide={disabledSeries.includes(nameof('cropland'))}
					/>
					<Bar
						dataKey={nameof('grazingLand')}
						stackId="a"
						fill={ordinal[1]}
						name="Grazing Land"
						hide={disabledSeries.includes(nameof('grazingLand'))}
					/>
					<Bar
						dataKey={nameof('timber')}
						stackId="a"
						fill={ordinal[2]}
						name="Timber"
						hide={disabledSeries.includes(nameof('timber'))}
					/>
					<Bar
						dataKey={nameof('wildlifeManagement')}
						stackId="a"
						fill={ordinal[3]}
						name="Wildlife Management"
						hide={disabledSeries.includes(nameof('wildlifeManagement'))}
					/>
					<Bar
						dataKey={nameof('other')}
						stackId="a"
						fill={ordinal[4]}
						name="Other"
						hide={disabledSeries.includes(nameof('other'))}
					/>
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
});
